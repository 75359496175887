import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Coller, MainContainer, Rower, ResponsiveImage, InlineText, Title} from '../theme';
import ProjectTile from '../components/project/ProjectTile';
import KbiList from '../components/kb/KbiList';
import SCQuery from '../components/helper/SCQuery'
import MetaTags from 'react-meta-tags';
import MemberListElement from '../components/member/MemberListElement'

const Hyperproject = (props) => 
        <SCQuery single={true} query={'*[_type=="hyperproject" && slug.current=="'+props.match.params.slug+'"][0]{_type, description, title, _id, "slug":slug.current, projects[]->{title, "slug":slug.current,  "cover":cover->image.asset->url}, resources[]->{title, _id, _type, "url":image.asset->url, "link":url}, "cover":cover->image.asset->url, members[]->{_id, firstname, lastname, "avatar":avatar.asset->url, shortbio, longbio, email, "slug":slug.current}, partners[]{link, "logo":logo.asset->url}}'}>
            {(hyperproject)=>{


                let resources = hyperproject.resources.filter(resource=>resource._type!=="image_kbi")

                return (
                    <MainContainer>
                        <MetaTags>
                <title>{"SEADS / " + hyperproject.title}</title>
                <meta name="description" content={hyperproject.description} />
                <meta property="og:title" content={"SEADS / " + hyperproject.title} />
                <meta property="og:image" content={hyperproject.projects && hyperproject.projects.length?hyperproject.projects[0].cover+"?w=720":"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
                    <Title>{hyperproject.title}</Title>
                    <Rower>
                    <Coller marginBottom sm={4} xs={12} style={{paddingTop: "10px"}}>
                            <KbiList data={resources} title="resources" limit={3}  />
                        </Coller>
                        <Coller marginBottom sm={8} xs={12} style={{paddingLeft: "30px"}}>
                            <InlineText>
                                <BlockContent blocks={hyperproject.description} style={{lineHeight: "500px"}} />
                                </InlineText>
                        </Coller>
                    </Rower>
                    <Rower>
                        {hyperproject.projects.map((project) => (
                            <Coller marginBottom key={`project-${project.slug}`} lg={3} md={4} sm={4} xs={12}>
                                <ProjectTile img={`${project.cover}?w=400`} link={`/project/${project.slug}`} title={project.title} />
                            </Coller>
                        ))}
                        {hyperproject.members ? <Coller xs={8} xsOffset={2} smOffset={4} mdOffset={4} sm={6} md={6}>
                            <Rower center="xs">
                                
                                <div style={{width: "100%", textAlign: "center"}}>
                                <Title style={{paddingBottom: "10px"}}>Participating SEADS members</Title>
                                </div>    
                                {hyperproject.members.map((member, index)=><MemberListElement contact={member.email} bioshort={member.shortbio} firstname={member.firstname} lastname={member.lastname} url={member.avatar} slug={member.slug} key={member.firstname + "-" + member.lastname}></MemberListElement>)}

                                {(hyperproject.partners && hyperproject.partners.length)? 
                                
                                <React.Fragment>
                                    <div style={{width: "100%", textAlign: "center"}}>
                                    <br/><br/>
                                        <Title style={{paddingBottom: "10px"}}>Partners</Title>
                                    </div>
                                    {hyperproject.partners.map(({link, logo})=><Coller key={link}> 
                                        <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                                            <ResponsiveImage src={logo} style={{maxWidth: "220px"}} />
                                        </a>
                                    </Coller>)}
                                </React.Fragment>
                                
                                : null}
                                
                            </Rower>
                        </Coller> 
                        : null}
                    </Rower>
                    <br/><br/><br/><br/><br/><br/>
                </MainContainer>
                )
            }}
        </SCQuery>

export default Hyperproject;
