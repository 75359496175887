import React, {useState} from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { CircleImageWithEffect, Coller,Title} from '../../theme';
import Button from "../../components/general/Button";
import Expand from 'react-expand-animated';

const MemberListElement = ({direct, url, firstname, lastname, bioshort, slug, showEmail, contact})=>{

    const [open, setOpen] = useState(false);
    const [transit, setTransit] = useState(false);
    const duration = 400;

    const toggle = ()=>{
        setOpen(!open);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
    }

    const handleImageClick = () => {
        if (direct){
            window.open("/member/"+this.state.data.slug,"_self");
        } else {
            toggle();
        }
    }

    if (!firstname || !lastname || !url){
        return null;
    }

    return (
        <Coller md={open||transit?12:3} 
                sm={open||transit?12:3}
                xs={open||transit?12:6}>
                <CircleImageWithEffect onClick={handleImageClick} src={`${url}?w=${open?800:400}`} />

                <Expand open={open} duration={duration} >
                    <Title>{firstname+" "+lastname}</Title>
                    <BlockContent blocks={bioshort} style={{lineHeight: "500px"}} />
                    <br/>
                    {showEmail? <Button text={contact} link={'mailto:'+contact} options={{external:true}} />: <Button text={"visit " + firstname +"'s page"} link={"/member/"+slug} />}
                    <br/><br/>
                </Expand>
        </Coller>
    );
}

export default MemberListElement;