import React, {useState} from 'react';
import { useSelector} from "react-redux";
import BlockContent from '@sanity/block-content-to-react'
import {CircleImage, Row, Col, Container, Wrapper, ContainerPadding, SubTitle, Line, InlineText} from '../../theme'
import {SendIcon, CloseIcon} from '../../theme/icons'
import Button from '../../components/general/Button'
import TextEditor from '../../components/general/TextEditor'
import ImageDrop from '../../components/general/ImageDrop'
import LineInput from "../../components/helper/LineInput"

const MemberOverview = ()=>{
    const [edit, setEdit] = useState(false);
    const [shortBioContent, setShortBioContent] = useState(null);
    const [longBioContent, setLongBioContent] = useState(null);
    const [updating, setUpdating] = useState(false);
    const member = useSelector(state => state.data.member);
    const client = useSelector(state => state.data.client);
    const [avatarTitle, setAvatarTitle] = useState('');
    const [avatarData, setAvatarData] = useState(null);
    const [website, setWebsite] = useState(useSelector(state=>state.data.member.website))
    const [instagram, setInstagram] = useState(useSelector(state=>state.data.member.instagram))
    const [twitter, setTwitter] = useState(useSelector(state=>state.data.member.twitter))
    const [facebook, setFacebook] = useState(useSelector(state=>state.data.member.facebook))
    const [whatsapp, setWhatsapp] = useState(useSelector(state=>state.data.member.whatsapp))

    const save=()=>{
        setUpdating(true);
        client.updateBio(shortBioContent, longBioContent, avatarData, member, website, instagram, twitter, facebook, whatsapp)
        .then((updatedmember)=>{
            client.refreshMember(member);
            setEdit(false);
            setUpdating(false);
        })
    }

    const getRegularContent = ()=><Row>
        <Col xs={8} xsOffset={2} smOffset={0} sm={3} md={12}>
            <CircleImage src={client.squareImage(member.avatar, 400)} />
        </Col>
        <Col xs={12} sm={9} md={12}>
            <Container>
                <SubTitle>Short Bio</SubTitle>
                <BlockContent blocks={member.shortbio} />
                <br/>
                <Line />
                <SubTitle>Long Bio</SubTitle>
                <BlockContent blocks={member.longbio} />
            </Container>
        </Col>
        <Col xs={12}>
            <Container>
                {member.email} <br/>
                {member.website} <br/>
                {member.instagram} <br/>
                {member.twitter} <br/>
                {member.facebook} <br/>
                {member.whatsapp} 
            </Container>
            
            <br />
            <Button text="edit" onClick={()=>{setEdit(true)}} />
            <Button text="visit page" link={`/member/${member.slug}`} options={{noTop:true}}/>
        </Col>
    </Row>

    const getEditContent = ()=><Row>
        <Col xs={12}>
            <Wrapper noBottom>
                <ContainerPadding>
                    <InlineText><i>Your whatsapp account will never be made public, the other socials are shown on your portfolio</i></InlineText> <br/><br/>
                    <LineInput title="website" value={website} setValue={setWebsite} NoBottom={true}/>
                    <LineInput title="instagram" value={instagram} setValue={setInstagram} NoBottom={true}/>
                    <LineInput title="facebook" value={facebook} setValue={setFacebook} NoBottom={true}/>
                    <LineInput title="twitter" value={twitter} setValue={setTwitter} NoBottom={true}/>
                    <LineInput title="whatsapp" value={whatsapp} setValue={setWhatsapp} NoBottom={true}/>
                    <Wrapper>
                        <Container>
                            <ImageDrop setTitle={setAvatarTitle} setValue={setAvatarData} text={"Drag 'n' drop a new avatar here, or click to select image"} />
                        </Container>
                    </Wrapper>
                </ContainerPadding>
                <br/>
                <SubTitle>Short Bio</SubTitle>
                <TextEditor value={shortBioContent} setValue={setShortBioContent} blocks={member.shortbio?member.shortbio:null}/>
                <br/>
                <SubTitle>Long Bio</SubTitle>
                <TextEditor value={longBioContent} setValue={setLongBioContent} blocks={member.longbio?member.longbio:null}/>
            </Wrapper>
        </Col>
        <Col xs={12}>
            <Button component={CloseIcon} onClick={()=>{setEdit(false)}} options={{noBottom:true}} />
            {updating?
            <Button text={"updating"} onClick={()=>{}} /> : 
            <Button component={SendIcon} onClick={()=>{save()}} />}
            
        </Col>
    </Row>
    
    if (member){
        return (edit?getEditContent():getRegularContent())
    } else {
        return null;
    }
}

export default MemberOverview;