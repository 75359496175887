import React from 'react'
import {Row, Col, Grid, Title} from '../theme'
import { FacebookIcon, TwitterIcon, LinkedInIcon, InstagramIcon } from '../theme/icons';
import ImageWithName from '../components/kb/ImageWithName'
import MemberList from '../components/member/MemberList'
import Segment from '../components/general/Segment'
import Footer, {FixedContainer} from '../components/general/Footer'
import MetaTags from 'react-meta-tags';

const SocialIcon = ({url, icon})=>{
    return  <Col xs={12} style={{transform: "translateX(-28px)"}}>
                <a href={url} target="_blank" style={{fontSize: "70px", textAlign:"center"}}>{icon}</a> 
            </Col> 
}

export const Socials = ()=>(
    <Row center='xs'>
            {[
                {url:"https://www.facebook.com/seads.network/", icon: <FacebookIcon />},
                {url:"https://www.instagram.com/seads.network/", icon: <InstagramIcon />},
                {url:"https://twitter.com/seads_network", icon: <TwitterIcon />},
                {url:"https://www.linkedin.com/company/28536448/", icon: <LinkedInIcon />}
            ].map(({url, icon})=>(<Col xs={2} xsOffset={1}>
                <SocialIcon url={url} icon={icon} key={url} />    
            </Col>))
            }
        </Row>
)

const Contact = ()=>{
    return <React.Fragment> 
            <MetaTags>
                <title>{"SEADS / INFO"}</title>
            </MetaTags>
            <Grid>
            <Row>
                <Col marginBottom xs={10} sm={4} xsOffset={1} smOffset={4}>
                    <br/><br/><br/>
                    <ImageWithName title="logo" />
                    <br/><br/><br/>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} sm={12} md={10} lg={8}>
                    <Socials />
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                <br/>
                </Col>
                
            </Row>
        <br/><br/>
        <Row center="xs">
            <Col xs={12}>
                
                <br/>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={12} sm={12} md={8} lg={6}>
                
            </Col>
        </Row>

        <Row center="xs">
                    <Col xs={12} sm={10} md={6}>
                        
                        <Title>
                            Collective 
                        </Title>
                        <Segment title="collective"/>
                        <br/>
                        <MemberList  notrole="network" />
                        <br/><br/><br/>
                        <Title>
                            Network
                        </Title>
                        <Segment title="network"/>
                        <br/>
                        <MemberList  role="network" />
                        <br/><br/><br/>
                        <Title>
                            Coordination team
                        </Title>
                        <br/>
                        <MemberList preventShuffle={true} role="coordination" showEmail={true}/>
                        <Segment title="contact"/>
                    </Col>
                </Row>
    </Grid>
    <Footer />
    </React.Fragment>
}

export default Contact;