import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import {Grid, Row, Col, Text, SuperTitle, CircleImage, ContainerSides, SubTitle, Wrapper, InlineText, ContainerPadding, Title} from '../theme'
import SCQuery from '../components/helper/SCQuery';
import BlockContent from '@sanity/block-content-to-react';
import Button, {Select} from '../components/general/Button'
import ProjectTile from '../components/project/ProjectTile';
import MetaTags from 'react-meta-tags';
import Dropdown from 'react-dropdown';
import "../index.css"
import { ListZoteroList } from '../components/kb/ZoteroList';

const Research = ()=>{
    // const [category, setCategory] = useState(0);
    // const categories = useSelector(state=>{
    //     if (state && state.data && state.data.categories){
    //         return [{title: "Featured"}].concat(state.data.categories.filter(f=>f.type === "education"))
    //     } else {
    //         return null;
    //     }
    // });


    const client = useSelector(state=>state.data.client);
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        if (client && !projects){
            client.getResearchProjects()
            .then(setProjects);
        }
        
    }, [client, projects]);

    

    if (true){

    return <Grid>
        <br/>
        <MetaTags>
                <title>{"SEADS / Research & Design"}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS / Research & Design"} />
                {/* <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} /> */}
            </MetaTags>
        <Row center="xs">
            <Col xs={12}>
                <SuperTitle>Research & Design</SuperTitle>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={12} md={8} lg={7}>
                <SCQuery single={true} query={"*[_type == 'siteSettings'][0]{research}"}>
                    {({research})=>{
                        return <BlockContent blocks={research.research} style={{lineHeight: "500px"}} />
                    }}
                </SCQuery>
            </Col>
        </Row>
        
        <Row center="xs">
                <Col xs={12}>
                    <Row center="xs">
                        {projects?projects.map(project=> <Col marginBottom key={`portfolio-${project.slug}`} lg={3} md={4} sm={4} xs={12}>
                                            <ProjectTile key={project.slug} img={`${project.cover}?w=400`} link={`/${project._type}/${project.slug}`} title={project.title} container/>
                                        </Col>):null}
                    </Row>
                </Col>

                <Col xs={12}>
                <br/><br/><br/><br/>
            </Col>
        
        </Row>

        <Row center="xs">
        <Col xs={12}>
            <b>
             <Title style={{textDecoration: "underline"}}>Articles</Title>
             </b>
        </Col>


        <Col xs={12} md={8} lg={7}>
                <SCQuery single={true} query={"*[_type == 'siteSettings'][0]{research}"}>
                    {({research})=>{
                        return <ListZoteroList list={research.zotero} />
                    }}
                </SCQuery>
                </Col>
        
            <Col xs={12}>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Col>
        </Row>
    </Grid>
    } else {
        return null;
    }
}

export default Research;