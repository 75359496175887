import React from 'react';
import { Coller, Rower } from '../../theme';
import ProjectTile from '../../components/project/ProjectTile';
import SCQuery from '../../components/helper/SCQuery'


const ProjectList = ({ title}) => 
   <SCQuery single={true} query={'*[_type=="projectgroup" && title=="'+title+'"][0]{projects[]->{title, _type, "slug":slug.current, "cover":cover->image.asset->url}}'}>
       {(projectlist)=>{
           if (!projectlist || !projectlist.projects || !projectlist.projects.length){
               return null;
           } else {
               return <Rower>
               {projectlist.projects.map((project) => 
                  <Coller marginBottom key={`portfolio-${project.slug}`} xs={10} xsOffset={1}>
                      <ProjectTile img={`${project.cover}`} link={`/${project._type}/${project.slug}`} title={project.title} container/>
                  </Coller>    
              )}
              </Rower>
           }
       }}
    </SCQuery>

export default ProjectList;
