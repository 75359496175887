import React from 'react';
import {settings} from "../../theme/config";
import Gallery from "../../components/general/Gallery";
import {BackgroundLanding} from '../../theme';
import SCQuery from '../../components/helper/SCQuery';
import shuffleArray from '../../utilities/ShuffleArray'
import Footer from '../general/Footer'

const AMOUNT = settings.landingamount;

const LandingsGallery = ({ title}) => {
    return <SCQuery query={"*[_type == 'resourcegroup' && title == '"+title+"']{title, resources[]->{'url':image.asset->url, 'id':_id}, 'id':_id}"}>
        {(images)=>{
            images = shuffleArray(images[0].resources);
            return (
                <BackgroundLanding>
                <Gallery images={images} randomAmount={AMOUNT} columnWidth={300}/>
                <Footer />
                </BackgroundLanding>
            )}}
    </SCQuery>
}

export default LandingsGallery;

