import React from 'react';
import ImageView from '../../pages/Image';
import ExtendedUrlView from '../../components/kb/ExtendedUrl';



const Kbi = ({id, type})=>{
switch (type) {
    case "image_kbi":
        return <ImageView id={id} />
    case "extended_url_kbi":
        return <ExtendedUrlView id={id} />
    default:
        return null;
    }
}

export default Kbi;