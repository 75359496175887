import React, {useEffect, useState} from 'react';
import { Grid, Row, Col, Title, Container, InlineText } from '../theme';
import './Lab.css';
import Noise from 'noisejs'
import Toggle from '../components/helper/ToggleInput'
import Slider from '../components/helper/SliderInput'
import SPG from '../components/experiments/SPG'
import Button from '../components/general/Button'
import Protected from '../components/helper/Protected'

// https://wakamaifondue.com/

const Effector = ({text, speed})=>{
    const [counter, setCounter] = useState(0);
    const [noise, setNoise] = useState(null);

    useEffect(()=>{
        if (!noise){
            setNoise(new Noise.Noise(123));
        }

        const intervalID = setInterval(() => {
            setCounter(counter+1);
        }, speed);   

        return ()=>clearInterval(intervalID);

    });

    const getValue = (index)=>{
        if (!noise){
            return 0;
        } else {
            const val = Math.floor(Math.abs(noise.simplex2(index*.1+counter*.01, 0)*1000))
            return val;
        }
    }

    const getText = ()=>{
        return text.split("").map((char, index)=><span key={index}
        style={{fontFamily: "Kyiv", fontSize: "4em", fontVariationSettings: `'CONT' ${getValue(index)}, 'wght' ${getValue(index+123)}`}}>
            {char}
        </span>)
    }

    if (noise){
        return <div>{getText()}</div>;
    } else {
        return null;
    }
}

const Lab = () => {
    const [speed, setSpeed] = useState(100);
    const experiments = [{
        title: "ĒoĒ Simplified Process Generator",
        description: "Simple generator to experiment with the dataflow. This tool is meant to generate two sets of code: the original code (left column) and the modified code (right columt) based upon two values: mortality and reproduction. The modification strategy is applied to every pixel (greyscale; 0-255)",
        links: [{title: "Github repository (Processing and Grashopper)", url: "https://github.com/SEADSNETWORK/EoESPG" }],
        component: SPG
    }];
    const [experiment, setExperiment] = useState(null);

    return <Protected>
     <Grid style={{width: "100%", padding: 0, margin: 0}}>
        <Row center="xs">
            <Col xs={12}>
                <br/>
                <Title>
                    <Effector text="SEADS LAB" speed={speed} />          
                </Title>
            </Col>
            <Col xs={12} md={6}>
                <Slider init={speed} min={0} max={110} setValue={(v)=>{setSpeed(v)}} />
            </Col>
            <Col xs={12}>
                {experiment?<Container>
                    <br/>
                    <Title>{experiment.title}</Title>
                    <br/>
                    <Row>
                        <Col xs={12} md={6} lg={4}>
                            <InlineText>{experiment.description}</InlineText>
                        </Col>
                        {experiment.links?experiment.links.map(({title, url})=><Col key={title} xs={12} md={6} lg={4}>
                            <Button text={title} link={url} options={{external: true}} />
                        </Col>):null}
                    </Row>
                    <br/><br/>
                    <experiment.component />
                    </Container>:null}
            </Col>
            <Col xs={12}>
                <br/><br/>
                <Title>
                    Experiments
                </Title>
            </Col>
            {experiments.map(({title, sketch}, index)=>{
                    return  <Col key={title+index} style={{width: "auto"}}><Button invert={experiment && title===experiment.title} key={title+index} onClick={()=>{setExperiment(experiments[index])}} text={title} /></Col>
                })}
                <br/><br/><br/><br/><br/><br/>
        </Row>
    </Grid>
    </Protected>
}
        
   
export default Lab;
