import React, {useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import {Collapse} from 'react-collapse';
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
import {FaSearch} from "react-icons/fa"
import {IoMdPricetag as TagIcon} from "react-icons/io"
import styled from 'styled-components';
import {Section, Grid, Row, Col, SubTitle, Text, MediumMax, MediumMin} from '../theme'
import {colors, settings, medias} from "../theme/config.json";
import Button, {ButtonContainer, ButtonSmall, ButtonCenter} from "../components/general/Button"
import SCQuery from '../components/helper/SCQuery'
import KbiList from '../components/kb/KbiList'
import Kbi from '../components/kb/Kbi'

import { toast } from 'react-toastify';

const InnerButtonCenter =  styled(ButtonCenter)`
    border-top: 0px;
    text-align: left;
    padding-left: 10px;
    background-color: ${props=>props.selected?colors.secondary:props.grey?"#dedede":colors.primary};
    color: ${props=>props.selected?colors.textSecondary:colors.textPrimary};
    transition: all .2s ease-in-out;
    &:hover {
        background-color: ${props=>props.selected?colors.secondary:props.grey?"#dedede":colors.primary};
        color: ${props=>props.selected?colors.textSecondary:colors.textPrimary};    
        cursor: crosshair;
        transform: scale(1.03);
      }
`

const InnerButtonSmall = styled(ButtonSmall)`
    border-top: 0px;
    border-left: ${props=>props.noLeft?'0px':null};
`

const OpenIconContainer = styled.span`
    text-align: right;
    float: right;
`
const FullButtonCenter = styled(ButtonCenter)`
    border-left: ${settings.borderline}px solid ${colors.textPrimary};
    border-right: ${settings.borderline}px solid ${colors.textPrimary};
    width: auto !important;
    padding: 7px;
`

const KBGrid = styled(Grid)`
    margin: 20px;
    padding: 0px;
    width: calc(100vw - 55px) !important;
    min-width:auto;
    max-width:auto;
    @media only screen and (min-width: 1800px) {
        width: 1800px !important;
        padding: auto;
        margin: auto;
        min-width:auto;
        max-width:auto;
      }

`

const KBContainer = styled.div`
    
`

const KBInnerContainer = styled.div`
width: 100%;
height: 100%;
padding-right: 20px; 
box-sizing: content-box;
`


export const TagMenu = ({initialOpen, taxonomy, onChangeTags, unselectTag})=>{
    const member = useSelector(state => state.data.member);
    const [isOpened, setIsOpened] = useState(initialOpen)
    const [tax, setTax] = useState(taxonomy);
    const [tagView, setTagView] = useState(null);
    const [renewView, setReniewView] = useState(false);
    
    let lastUpdate = new Date();

    const doActionInTree = (el, action, endaction)=>{
        let newDate = new Date();
        if (newDate-lastUpdate > 100){
            const tagsThatAreSelected = [];
            const changer = (list)=>{
                list.forEach((el2)=>{
                    if (el._id === el2._id){
                        action(el2);
                    }
                    if (el2.selected){
                        tagsThatAreSelected.push(el2);
                    }
                    if(el2.children && el2.children.length>0){
                        changer(el2.children);
                    }
                })
            }
            changer(tax);
            endaction();
            onChangeTags(tagsThatAreSelected);
            lastUpdate = newDate;
        } 
    }

    const checkIfParentAndChildAreSelected = (lastchanged)=>{
        const changer = (list, value, theProblemParent)=>{
            list.forEach((el)=>{
                if (value && el.selected){
                    let text;
                    if (el == lastchanged){
                        text = "You have selected '"+el.title+"', which is already part of your search because of '"+theProblemParent.title+"'";
                    } else {
                        text = "You have selected '"+theProblemParent.title+"' which already includes '"+el.title+"'";
                    }
                    toast(text, {hideProgressBar: true});
                }

                if(el.children && el.children.length>0){
                    let newProblem = null;
                    if (theProblemParent){
                        newProblem = theProblemParent;
                    } else if (el.selected){
                        newProblem = el;
                    }
                    changer(el.children, value || el.selected, newProblem);
                } 
            })
        }
        changer(tax, false);
    }

    const toggleElement = (el, useValue, value)=>{
        setTimeout(() => {
            doActionInTree(el, (el)=>{
                el.selected = useValue?value:!el.selected;
            }, ()=>{
                setTax(tax);
                setReniewView(true);
                checkIfParentAndChildAreSelected(el);
            })
        }, 10);
    }

    const toggleOpen = (el)=>{
        doActionInTree(el, (el)=>{
            el.open = !el.open;
            el.selected = false;
        }, ()=>{
            setTax(tax);
            setReniewView(true);
        })
    }

    useEffect(() => {  
        if (!tagView || renewView){
            setTagView((()=>{
                let workingTax = tax;
                const populate = (list, depth, shouldBeGrey)=>{
                    const rv = [];
                    list.forEach((element, index)=>{

                        if (member || !element.private){
                            let renderedChildren = null;
                            let openButton = null;

                            if (element.children && element.children.length>0){

                                if (!('open' in element)){
                                    element.open = true;
                                }
                                
                                openButton = ( <OpenIconContainer onClick={()=>{toggleOpen(element)}} >{!element.open?<IoIosArrowDown />:<IoIosArrowUp />}</OpenIconContainer>)
                                if (element.open){
                                    renderedChildren = populate(element.children, depth+1, shouldBeGrey || element.selected);
                                }
                            }
            
                            let innerCount = [];
                            for (let i = 0; i < depth; i++) {
                                innerCount.push(<InnerButtonSmall noLeft={i!==0} key={element.slug+index+"-"+i}/>)    
                            }

                            let title = null;

                            if ((depth===1 && list.length===1)){
                                title = "ALL";
                            } else if (!element.children || element.children.length==0){
                            title = <span><TagIcon /> {element.title}</span>
                            } else {
                                title=<b>{element.title}</b> 
                            }



            
                            rv.push(
                                <Section key={element.slug+index} style={{width: '100% !important'}}>
                                <ButtonContainer>
                                    {innerCount}
                                    <InnerButtonCenter grey={shouldBeGrey} selected={element.selected} onClick={()=>{toggleElement(element)}}>
                                        {element.private?<i>{title}</i>:title} 
                                    </InnerButtonCenter>
                                    <InnerButtonSmall >{openButton} </InnerButtonSmall>
                                </ButtonContainer>
                                {renderedChildren}
                                </Section>
                            )
                            
                        }
                    })
                    return rv;
                }
        
                let renderedElements = populate(workingTax, 1, false);
                if (!tagView){
                    setTax(workingTax);
                }
                setReniewView(false);
                return renderedElements;
            })())
        } 
    
        if (unselectTag){
            setTimeout(() => {
                toggleElement(unselectTag, true, false)
                unselectTag = null;
            }, 100);
        }

    }, [tagView, tax, toggleElement, renewView]);

    return (
        <Section>
            <Button text="TAGS" onClick={()=>{setIsOpened(!isOpened)}} />
            <Collapse isOpened={isOpened}>
                {tagView}
                <Button text="HIDE TAGS" onClick={()=>{setIsOpened(false)}} options={{noTop:true}} />
            </Collapse>
        </Section>
    )
}



//todo: set amount
const KB = (props)=>{
        const storedata = useSelector(state => state.data);
        const [selectedTags, setSelectedTags] = useState([]);
        const [unselectTag, setUnSelectTag] = useState(null);
        let [searchValue, setSearchValue] = useState('');
        let [confirmedSearchValue, setConfirmedSearchValue] = useState(null);

        const ANDER = <b>&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;&nbsp;&nbsp;</b> 

        let selectedTagsRender = ()=>{
            
            return selectedTags.map((tag, index)=>{
                return (<span key={index}>
                            <FullButtonCenter onClick={()=>{setUnSelectTag(tag)}}>
                                {tag.title}
                            </FullButtonCenter>
                            {index < (selectedTags.length -1)? ANDER : null}
                        </span>)
                    
            });
        }

        let renderSearchEntry = ()=>{
            if (confirmedSearchValue){
                return (<span>
                            {selectedTags.length?ANDER:null}
                            <FullButtonCenter onClick={()=>{setConfirmedSearchValue(null)}}>
                                {confirmedSearchValue}
                            </FullButtonCenter>
                        </span>)
            } else {
                return null;
            }
        }

        const getExpandedChildren = (tag)=>{
            let ids = []

            const makeFlatList = (tag)=>{
                tag.children.forEach((child)=>{
                    if (child.children && child.children.length>0){
                        makeFlatList(child)
                    } else {
                        ids.push(child._id);
                    }
                });
            }
            
            makeFlatList(tag);

            let rv = '';
            ids.forEach((id, index)=>{
                rv += 'references("'+id+'")'
                if (index < ids.length-1){
                    rv += ' || '
                }
            })
            return rv;
        }

        let query = ()=>{
            if (selectedTags.length || confirmedSearchValue){
                let rv = `*[_type match '*_kbi' && _type!='tag_kbi'`;
                
                selectedTags.forEach((tag)=>{
                    if (tag.children && tag.children.length>0){
                        rv += '&& (' + getExpandedChildren(tag) + ')';
                    } else {
                        rv+= ' && references("'+tag._id+'")';
                    }
                })
                if (confirmedSearchValue){
                    rv += ' && title match("'+confirmedSearchValue+'") '
                }
                rv += ']  | order(_updatedAt desc) {title, _id, _type, "thumbnail":thumbnail.asset->url, star}'
                return rv;
            } else {
                return null;
            }
        }

        useEffect(() => {
            if (unselectTag){
                setUnSelectTag(null);
            }
        }, [unselectTag, setUnSelectTag]);

        const updateInputValue =(evt)=>{
            setSearchValue(evt.target.value);
        }

        const checkSubmit = (event)=>{
            if (!event || (event.key && event.key === "Enter")){
                setConfirmedSearchValue(searchValue);
                setSearchValue('');
            }
        }

        let Tagmenu = ()=><TagMenu taxonomy={ props.taxonomy || storedata.taxonomy} initialOpen={true} unselectTag={unselectTag} onChangeTags={(tags)=>{setSelectedTags(tags)}} />
        let KBIList = ()=>(query()?
                            <Section>
                            <SubTitle>
                                Found
                            </SubTitle>
                        <SCQuery single query={query()}>
                        {(list)=>{

                            list.sort(function(x, y) {
                                return (x.star === y.star)? 0 : x.star? -1 : 1;
                            });

                            return (
                                <Section>
                                    <KbiList data={list} saveStore limit={window.innerWidth < medias.mdp ? 3 : 10 } />
                                </Section>
                            )}}
                        </SCQuery>
                    </Section>:null)
        let Search = <Section style={{ marginBottom: "", border: "3px solid black", padding: "3px"}}> 
                             <input type="text" name="name" style={{display: "inline-block", width: "85%"}} 
                                     value={searchValue} 
                                     onChange = {updateInputValue}
                                     onKeyPress={(event)=>{checkSubmit(event)}} /> 
                             <FaSearch style={{marginLeft: "20px", textAlign: "right"}} onClick={(event)=>{checkSubmit()}}/>
                         </Section>
        
        let ShowQuery = ()=><Section>
                                <SubTitle>
                                    {!selectedTags.length && !confirmedSearchValue?"":"Searching for"}
                                </SubTitle>
                                {selectedTagsRender()}{renderSearchEntry()}
                            </Section>

        let KBI = ()=>{
            if (storedata.currentKBI){
                return <Section style={{border: "3px solid black", padding: "10px"}}>
                            <Kbi id={storedata.currentKBI._id} type={storedata.currentKBI._type}/>   
                            </Section>
            } else {
                return null;
            }
        }

        let xs=12, sm=12, md=4, lg=4;
        if (storedata.taxonomy){
            return <KBGrid>
                        <br/><br/><br/><br/>
                        <MediumMin>
                            <Row>
                                <Col xs={xs} sm={sm} md={md} lg={lg}>
                                {props.intro?props.intro:null}
                                <br/>
                                <KBI />
                                <br/>
                                <KBIList />
                                <br/>
                                <SubTitle>
                                    Select a tag
                                </SubTitle>
                                <Tagmenu />
                                <br/>
                                <SubTitle>
                                    Refine your search
                                </SubTitle>
                                {Search}
                                {props.taxonomy?<Text align="center">
                                {props.search?props.search:"Try searching for e.g. shield, face mask or respirator"}
                                            </Text> : null}
                                <br />
                                <ShowQuery />
                                </Col>
                            </Row>
                        </MediumMin>
                        <MediumMax>
                            <Row>
                            {props.title?<Section>
                                                {props.title}
                                                <br />
                                            </Section>:null}
                            {props.text?<Section>{props.text} <br/><br/><br/> </Section>:null}
                            </Row>
                            <Row>
                                <Col xs={xs} sm={sm} md={md} lg={lg}>
                                    <KBContainer> 
                                        <KBInnerContainer>
                                            
                                            <SubTitle>
                                                Select a tag
                                            </SubTitle>
                                            <Tagmenu />
                                            <br/>
                                            <SubTitle>
                                                Refine your search
                                            </SubTitle>
                                            {Search}
                                            {props.taxonomy?<Text align="center">
                                                {props.search?props.search:"Try searching for e.g. shield, face mask or respirator"}
                                            </Text> : null}
                                            
                                            <br />
                                            <ShowQuery />
                                        </KBInnerContainer>
                                    </KBContainer>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={md}>
                                    <KBContainer> 
                                        <KBInnerContainer>
                                            <KBIList />
                                        </KBInnerContainer>
                                    </KBContainer>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={md}>
                                    <KBContainer> 
                                        <KBInnerContainer>
                                            <br/><br/><br/>
                                            <KBI />
                                        </KBInnerContainer>
                                    </KBContainer>
                                </Col>
                            </Row>
                        </MediumMax>
                        
                    

            </KBGrid>
        } else {
            return <div>loading taxonomy</div>
        }        
    }


export default KB;

