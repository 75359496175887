import React from 'react';
import {Rower, Coller, Line} from '../../theme';
import Gallery from "../../components/general/Gallery";

const ImageList = ({ imagegroup}) => {
    if (!(imagegroup && imagegroup.length)) return null;
    let images = [];
    imagegroup.forEach((image)=>{
        images.push({id: image._id, url: image.url});
    });
    let imageGallery = <Gallery images={images} randomAmount={0} columnWidth={250}/>

    return (
        <Rower>
            <Coller xs={12}>
                {imageGallery}
            </Coller>
            <Coller xs={12}>
                <Line/>
                <br/>
            </Coller>
            </Rower>   
    )
}

export default ImageList