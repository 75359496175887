import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import toast from '../utilities/toast'
import imageUrlBuilder from '@sanity/image-url'

const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: true
    })

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    const CreateTaxonomy = ()=>{
        const handleError = (error)=>{
            console.log(error);
        }
        
        fetch('*[_type=="tag_kbi"]{title, _id, children, "slug":slug.current, private}')
        .then((allTags)=>{

            const getTag = (reference)=>{
                let rv = {};

                allTags.forEach((originalTag, index)=>{
                    if (originalTag._id == reference) {
                        rv = originalTag;
                        allTags[index].hasLinked = true;
                        return rv;
                    }
                })

                if (rv._id){
                    return rv;
                } else {
                    return {error:'not found'};
                }
            }

            allTags.forEach((tag, index) => {
                const populate = (tag)=>{
                    if (tag.children && tag.children.length > 0) {
                        tag.children.forEach((child, childindex)=>{
                            if (child._ref){
                                tag.children[childindex] = getTag(child._ref);
                                populate(tag.children[childindex]);
                            }
                        })
                    } else {
                        return;
                    }
                }
                populate(tag);                    
            });

            dispatch({type: DATA_ACTIONS.SET_TAGS, tags: allTags});
            dispatch({type: DATA_ACTIONS.SET_TAXONOMY, taxonomy: allTags.filter((tag)=>!tag.hasLinked)})
        }).catch(handleError)
    }

    const LoadCategories = ()=>{
        fetch('*[_type == "projectCategory"]')
        .then((categories)=>{
            dispatch({type: DATA_ACTIONS.SET_CATEGORIES, categories});
        })
    }

    const getEducationalProjects = ()=>{
        return new Promise((resolve, reject)=>{
            const selector = "{_type, title, _id, 'slug':slug.current, projects, 'cover':cover->image.asset->url, 'category' : categories->title}";
            fetch("*[_type=='projectgroup' && title=='education portfolio']{projects[]->"+selector+"}")
            .then((data)=>{
                const featured = data[0].projects.reduce((o, key) => ({ ...o, [key.slug]: key}), {})
                fetch("*[(_type == 'project' || _type == 'hyperproject') && type=='education']"+selector)
                .then(projects=>{
                    projects.forEach(p=>{p.featured = featured[p.slug]!==undefined})
                    resolve(projects)
                }) 
            })
        })
    }

    const getResearchProjects = ()=>{
        return new Promise((resolve, reject)=>{
            const selector = "{_type, title, _id, 'slug':slug.current, projects, 'cover':cover->image.asset->url, 'category' : categories->title}";
            fetch("*[_type=='projectgroup' && title=='research portfolio']{projects[]->"+selector+"}")
            .then((data)=>{
                resolve(data[0].projects);
            })
        })
    }

    const fetch = (query)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query]){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const getMemberRef = (member)=>{
        return {_ref: member._id, _type: "reference"}
    }

    const memberSelectors = '{_id, firstname, lastname, "avatar":avatar.asset->url, shortbio, longbio, email, "slug":slug.current, whatsapp, instagram, facebook, website, twitter}';
    const login = (email)=>{
        return new Promise((resolve, reject)=>{
            fetch('*[email == "'+email+'"][0]'+memberSelectors)
            .then((member)=>{
                if (member.firstname){
                    toast("Welcome home " + member.firstname)
                    dispatch({type: DATA_ACTIONS.SET_LOGGED_IN_MEMBER, member})
                    resolve(member);
                } else {
                    let msg = 'member not found';
                    toast(msg);
                    reject(msg);
                }
            }).catch(reject);
        });
    }

    const logout = ()=>{
        return new Promise((resolve, reject)=>{
            dispatch({type: DATA_ACTIONS.SET_LOGGED_IN_MEMBER, member: null});
            toast("Logout succesful")
            resolve();
        })
    }

    const refreshMember = (member)=>{
        sc.fetch('*[_id == "'+member._id+'"][0]'+memberSelectors)
        .then((data)=>{
            dispatch({type: DATA_ACTIONS.SET_LOGGED_IN_MEMBER, member: data})
        })
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const updateBio = (shortbio, longbio, avatar, member, website, instagram, twitter, facebook, whatsapp)=>{
        const newData = {website, instagram, twitter, facebook, whatsapp}

        if (shortbio){
            newData.shortbio = shortbio;
        }

        if (longbio){
            newData.longbio = longbio;
        }

        if (avatar){
            return new Promise((resolve, reject)=>{
                uploadImage(member.name+'-avatar.jpg', avatar)
                .then((document)=>{
                    newData.avatar = makeImageReference(document._id);
                    sc.patch(member._id).set(newData).commit()
                    .then((updatedmember)=>resolve(updatedmember));
                })
            })
        } else {
            return sc.patch(member._id).set(newData).commit()
        }
    }

    /**
     * HIT IT
     */
    CreateTaxonomy();
    LoadCategories();
    getEducationalProjects();
    getResearchProjects();

    if (environment.dev){
        environment.printstatus()
    } 

    return {
        fetch,
        environment,
        login, logout,
        squareImage,
        updateBio,
        refreshMember, memberSelectors, getEducationalProjects, getResearchProjects
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

