import React from 'react';
import {Container} from "../theme"
import Segment from '../components/general/Segment'
import MetaTags from 'react-meta-tags';

const Legal = () => (
    <Container>
        <MetaTags>
            <title>{"SEADS / legal"}</title>
            <meta name="description" content={"Cookie policy for visitors and members"} />
            <meta property="og:title" content={"SEADS / legal"} />
        </MetaTags>
    <Segment title="legal"/>
    </Container>
)

export default Legal;
