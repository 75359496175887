import React from 'react';
import { useSelector} from "react-redux";
import {Section} from '../../theme';

const Protected = ({children})=>{
    const storedata = useSelector(state => state.data);
    if (!storedata.member ){
        return <h1>You need to be logged in to see this content</h1>
    } else {
        return <Section>
                {children}
        </Section>
    }
}


export default Protected;