import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import BlockContent from '@sanity/block-content-to-react';
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText' 
import { Coller, MainContainer, ResponsiveImage, Rower, InlineText, Title, OnlySmall, ContainerSides } from '../theme';
import SCQuery from '../components/helper/SCQuery';
import MemberListElement from '../components/member/MemberListElement'
import ProjectTile from '../components/project/ProjectTile';

const Chapter = (props) => {
    let savedChapter = useSelector(state=>state.data.currentChapter);
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (data && (!savedChapter || (savedChapter.title !== data.title))){
            dispatch({ type: DATA_ACTIONS.SET_CURRENT_CHAPTER, currentChapter: data});
        }
    });


return <SCQuery single={true} query={"*[_type == 'chapter' && slug.current=='"+props.match.params.slug+"'][0]{_type, description, title, _id, 'slug':slug.current, 'logo':logo.asset->url, members[]->, members[]->{_id, firstname, lastname, 'avatar':avatar.asset->url, shortbio, longbio, email, 'slug':slug.current}, projects[]->{_type, title, _id, 'slug':slug.current, projects, 'cover':cover->image.asset->url}}"}>
{(chapter)=>{
    
    if (!data || (data.title !== chapter.title)){
        setData(chapter);
    }

    return (
        <MainContainer>
             <MetaTags>
                <title>{"SEADS / " + chapter.title}</title>
                <meta name="description" content={BlocktoPlainText(chapter.description)} />
                <meta property="og:title" content={"SEADS / " + chapter.title} />
                <meta property="og:image" content={chapter.cover+"?w=720"} />
            </MetaTags>
            <Rower center='xs'>
                <Coller xs={12} style={{maxWidth: "200px", marginTop: "27px"}}>
                    <ResponsiveImage src={`${chapter.logo}?w=${Math.floor(window.innerWidth*.8)}`} alt={chapter.title} />
                    <OnlySmall>
                        <br/><br/><br/>
                    </OnlySmall>
                </Coller>
                <Coller xs={12} md={8}>
                    <ContainerSides size="10px">
                        <InlineText>
                            <BlockContent blocks={chapter.description} style={{lineHeight: "500px"}} />
                        </InlineText>
                    </ContainerSides>
                </Coller>
            </Rower>

            <Rower center="xs">
                <Coller xs={12}>
                    <br/>
                    <Title style={{paddingBottom: "10px"}}>projects</Title>
                </Coller>
                {chapter.projects.map((project) => {
                    let sublink = project._type;
                    return (
                        <Coller marginBottom key={`portfolio-${project.slug}`} lg={3} md={4} sm={4} xs={12}>
                            <ProjectTile img={`${project.cover}?w=400`} link={`/${sublink}/${project.slug}`} title={project.title} container/>
                        </Coller>
                    )})}
            </Rower>
            
            
            <Rower center="xs">
                <Coller xs={12}>
                    <br/><br/>
                </Coller>
                {chapter.members ? <Coller xs={8} sm={6} md={6}>
                    <Rower center="xs">
                        <div style={{width: "100%", textAlign: "center"}}>
                        <Title style={{paddingBottom: "10px"}}>members</Title>
                        </div>
                        {chapter.members.map((member, index)=><MemberListElement contact={member.email} bioshort={member.shortbio} firstname={member.firstname} lastname={member.lastname} url={member.avatar} slug={member.slug} key={member.firstname + "-" + member.lastname}></MemberListElement>)}
                    </Rower>
                </Coller> 
                : null}
            </Rower>
            <br /><br />
        </MainContainer>
    )}}
</SCQuery>
}
    


export default Chapter;
