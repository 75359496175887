import React, {useState} from 'react';
import { useSelector} from "react-redux";
import Protected from '../components/helper/Protected'
import {Grid, Row, Col, Title} from '../theme'
import CollapsibleContainer from '../components/helper/CollapsibleContainer';
import MemberOverview from '../components/member/MemberDetails'
import Drafts from '../components/other/Drafts'
import Button from '../components/general/Button'
import MemberList from '../components/member/MemberList'
import MemberTable from '../components/member/MemberTable'
import MemberProjects from '../components/member/MemberProjects'
import Calendar from '../components/other/Calendar';
import CommunityMeetings from '../components/community/CommunityMeetings'


const Dashboard = ()=>{
    const member = useSelector(state => state.data.member);

    if (!member){
        return null;
    }

    return <Protected>
        <Grid>
                <br/>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <CollapsibleContainer title={member.firstname} beginOpen={false}>
                            <MemberOverview />
                       </CollapsibleContainer>
                       <CollapsibleContainer title="calendar">
                            <Calendar />
                       </CollapsibleContainer>
                       <CollapsibleContainer title="platform status">
                           <Row>
                               <Col xs={6}>
                                <b>SEADS CMS</b> 
                               </Col>
                               <Col xs={6}>
                                    <img src={"https://api.netlify.com/api/v1/badges/3767d618-a9c9-4468-bfec-a723cfa5cd6b/deploy-status"} /> 
                               </Col>
                               <Col xs={6}>
                                    <b>SEADS PLATFORM</b>
                               </Col>
                               <Col xs={6}>
                                    <img src={"https://api.netlify.com/api/v1/badges/5a32b7d4-ad15-4d39-94e0-57220ebedef8/deploy-status"} />
                               </Col>
                           </Row>
                           
                           
                           
                          
                            
                       </CollapsibleContainer>
                    </Col>
                    <Col xs={12} sm={12} md={6}>
                        <CollapsibleContainer title="draft items">
                                <Drafts />
                        </CollapsibleContainer>
                        <CollapsibleContainer title="Links">
                            <Button text="CMS" link="https://cms.seads.network/" options={{external: true, noBottom: true}} />
                            <Button text="Projects overview" link="/overview" options={{noBottom: true}} />
                            <Button text="POST TO INSTA" link="https://bit.ly/3dddPbl" options={{noBottom: true, external: true}} />
                            <Button text="STUFF" link="/stuff" options={{noBottom: true}} />
                            <Button text="LAB" link="/lab" options={{noBottom: true}} />
                            <Button text="Covid 19 and KB insights" link="/covidinsights" options={{noBottom: true}} />
                            <Button text="Website Feedback" link="https://docs.google.com/document/d/10U1sfsqlp-xMoVKqcj86U3rHYq9fOBARpEqXYNdIg08/edit" options={{external: true}} />
                        </CollapsibleContainer>
                        <CollapsibleContainer title="Community Meetings"  noPadding noWrapper>
                            <CommunityMeetings />
                        </CollapsibleContainer>
                    </Col>
                    <Col xs={12}>
                        <CollapsibleContainer title="members" beginOpen={false}>
                                <MemberTable />
                        </CollapsibleContainer>
                    </Col>
                </Row>
                <br/><br/>
                <Title>Projects</Title>
                <MemberProjects member={member} />
                
            </Grid>
    </Protected>


}

export default Dashboard;