import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {colors, fonts, margins, settings, medias} from "../../theme/config.json";
import {InlineText, UnderLine} from "../../theme";


export const ButtonContainer = styled.span`
  display:table;
  width: ${props => props.inline ? 'auto !important' : '100% !important'};
`;
 
export const ButtonSmall = styled.div`
  border: ${settings.borderline}px solid ${colors.textPrimary};
  border-top: ${props=>props.noTop?'0px':null};
  border-bottom: ${props=>props.noBottom?'0px':null};
  width: ${settings.containerpadding}px !important;
  display:table-cell;
  padding: ${settings.containerpadding}px;
  border-left: ${props=>props.noLeft?'0px':null};
  border-right: ${props=>props.noRight?'0px':null};
  background-color: ${colors.primary};
`;

export const ButtonCenter = styled.div`
  padding: ${settings.containerpadding}px;
  border-top: ${props=>props.noTop?'0px':settings.borderline+"px solid "+colors.textPrimary};
  border-bottom: ${props=>props.noBottom?'0px':settings.borderline+"px solid "+colors.textPrimary};
  padding-top: ${settings.containerpadding*1.3}px;
  padding-bottom: ${settings.containerpadding*1.3}px;
  margin-left: ${margins.text.left};
  margin-top: ${margins.text.top};
  text-align: center;
  display:table-cell;
  text-transform: ${fonts.transform.title};
  background-color: ${props=>props.invert?colors.secondary:colors.primary};
  color: ${props=>props.invert?colors.textSecondary:colors.textPrimary};
  &:hover {
    background-color: ${props=>props.invert?colors.primary:colors.secondary};
    color: ${props=>props.invert?colors.textPrimary:colors.textSecondary};
    cursor: crosshair;
  }
`;

export const TextButton = ({text, link, options})=>{
    options = options || {};
    if (options.external){
        return (
            <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                <UnderLine>{text}</UnderLine>
            </a>
        )
    } else {
        return (
            <Link to={link} style={{textDecoration: "none"}}>
                <UnderLine>{text}</UnderLine>
            </Link>
        )
    }
}

export const Select = styled.select`
padding: ${settings.containerpadding}px;
background-color:  ${props=>props.invert?colors.primary:colors.secondary}; 
padding-top: ${settings.containerpadding*1}px;
padding-bottom: ${settings.containerpadding*1}px;
margin-left: ${margins.text.left};
margin-top: ${margins.text.top};
text-align: left;
display:table-cell;
text-transform: ${fonts.transform.title};
color: ${props=>props.invert?colors.secondary:colors.primary}; 
font-weight: ${fonts.weight.boldW};
letter-spacing: 2px;
font-size: 25px;
border: ${props=>props.invert?null:"3px solid "+colors.secondary}; 
border-top: ${props=>props.noTop?"0px":null};

@media only screen and (max-width: ${medias.smp}px) {
  font-size: 15px;
  letter-spacing: auto;
}

&:hover {
  background-color: ${props=>props.noHover?null:colors.primary};
  color: ${props=>props.noHover?null:colors.secondary};
  cursor: ${props=>props.noHover?null:"pointer"} pointer;
  border: ${props=>props.noHover?null:"3px solid black"} ;
  
}
`

const Button = ({ inline, link, text, onClick, options, component, invert }) => {
    options = options || {};
    const hideTop = options.noTop;
    const hideBottom = options.noBottom;
    const hideSides = options.noSides;

    let renderInner = (
        <ButtonCenter noTop={hideTop} noBottom={hideBottom} invert={invert}>
            {component?component():<span style={{fontSize: fonts.size.book, fontWeight: options.bold?"bold":"normal"}} dangerouslySetInnerHTML={{ __html: text}} />}
        </ButtonCenter>
    )

    let sml = <ButtonSmall noTop={hideTop} noBottom={hideBottom} noLeft={hideSides}/>
    let smr = <ButtonSmall noTop={hideTop} noBottom={hideBottom} noRight={hideSides}/>
    

    if (link) {
        if (options.external){
            return (
                <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                    <ButtonContainer inline = {inline? true : false}>
                    {sml}{renderInner}{smr}
                    </ButtonContainer>
                </a>
            )
        } else {
            return (
                <Link to={link} style={{textDecoration: "none"}}>
                    <ButtonContainer inline = {inline? true : false}>
                    {sml}{renderInner}{smr}
                    </ButtonContainer>
                </Link>
            )
        }
    } else if (onClick) {
        return (
            <ButtonContainer inline = {inline? true : false} onClick={onClick}>
                {sml}{renderInner}{smr}
            </ButtonContainer>
        )
    } else {
        return (
            <h1>this is probs not a button</h1>
        )
    }
}

export default Button