import React, {useEffect} from 'react';
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Store from "../Store";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sanityclient from '../services/client';
import { css } from 'glamor';

//components
import Nav from '../components/other/Nav';
import {CommunityNotifier} from '../components/community/CommunityMeetings'

//pages
import Chapter from './Chapter';
import Chapters from './Chapters';
import Home from './Home';
import Hyperproject from './Hyperproject';
import Project from './Project';
import Portfolio from './Portfolio';
import ImageView  from './Image';
import Calendar from './Calendar';
import Legal from './Legal';
import NCov from './ncov'
import KB from './KB'
import Member from './member'
import CovidInsights from './CovidInsights'
import Dashboard from './Dashboard'
import Contact from './contact'
import Lab from './Lab'
import Education from './Education'
import Stuff from './Stuff'
import Groovember from './Groovember';
import Research from './research';
import Overview from './Overview';

const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      toast.configure()
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
    } 
  })
  return (
    null
  )
}

const Toaster = ()=>{
  const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
    <i onClick={closeToast}></i>
  );
  return <ToastContainer
    toastClassName={css({color:"black", border: "solid black 3px"})}
    closeButton={<CloseButton/>}
  />
}

const history = createBrowserHistory()

const App = () => {
  return(
  <Provider store={Store}>
    <DataHelper />
    <CommunityNotifier />
    <Toaster />
      <Router history={history}>
        <span>
          <Nav history={history}/>
          <Switch>
            <Route exact path="/chapter/:slug" component={Chapter} />
            <Route exact path="/chapters" component={Chapters} />
            <Route exact path="/legal" component={Legal} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/hyperproject/:slug" component={Hyperproject} />
            <Route exact path="/project/:slug" component={Project} />
            <Route exact path="/projects" component={Portfolio} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route exact path="/image/:id" component={ImageView}></Route>
            <Route exact path="/covid19-tech" component={NCov} />
            <Route exact path="/covid19-tech/:id" component={NCov} />
            <Route exact path="/knowledge-base" component={KB} />
            <Route exact path="/kb" component={KB} />
            <Route exact path="/stuff" component={Stuff} />
            <Route exact path="/stuff/:id" component={Stuff} />
            <Route exact path="/groovember" component={Groovember} />
            <Route exact path="/groovember/:id" component={Groovember} />
            <Route exact path="/member/:slug" component={Member} />
            <Route exact path="/member" component={Member} />
            <Route exact path="/members" component={Member} />
            <Route exact path="/covidinsights" component={CovidInsights} />
            <Route exact path="/dashboard" component={Dashboard} />
            <Route exact path="/lab" component={Lab} />
            <Route exact path="/lab/:slug" component={Lab} />
            <Route exact path="/info" component={Contact}/>
            <Route exact path="/education" component={Education}/>
            <Route exact path="/research" component={Research}/>
            <Route exact path="/overview" component={Overview}/>
            <Route exact path="/" component={Home} />
            <Route component={Home}/>
          </Switch>
        </span>
      </Router>
  </Provider>
)};

export default App;
