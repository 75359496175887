import React from 'react';
import Calendar from '../components/other/Calendar';
import Footer, {FixedContainer} from '../components/general/Footer'

const CalendarPage = () => (
    <div>
        <Calendar />
        <FixedContainer>
            <Footer />
        </FixedContainer>
    </div>
)

export default CalendarPage;
