import React from 'react';
import { CenterContainer, ErrorMessage, Overlay, SubTitle} from '../../theme';

let production = true;
if(process.env.NODE_ENV === 'dev') {
    production = false;
}

const Error = ({ error, title, text }) => {
    let errorMsg = 'Something went wrong';
    if(production && text) {
        errorMsg = text;
    } else if(error) {
        errorMsg = error
    }
    return (
    <Overlay>
        <CenterContainer>
            { title ? <SubTitle>{title}</SubTitle> : <SubTitle>Error</SubTitle>}
            <ErrorMessage>{errorMsg}</ErrorMessage>
        </CenterContainer>
    </Overlay>
    )
}


export default Error;