import React, { Fragment } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Button from '../../components/general/Button';
import { Panel, PanelTitle, SquareImage, Text, InlineText, Container} from '../../theme';
import trimCharacters from 'trim-characters';


const TextPanel = ({ img, link, text, title }) => {
    
    if (typeof text!=="string"){
        text = <BlockContent blocks={text} />
    }

    return (
        <Fragment>
            <Panel noBorderBottom={link ? true : null}>
                {img ? <SquareImage borderBottom width='100%' img={img} /> : null}
                {title ? <Container><PanelTitle>{title}</PanelTitle></Container> : null}
                {text? <Container><InlineText>
                    <span style={{overflow: "hidden", display: "-webkit-box", WebkitLineClamp: "7", WebkitBoxOrient: "vertical"}}>
                        {text}
                    </span>
                    </InlineText></Container> : null}
            </Panel>
            {link ? <Button link={link} text='more' options={{noTop:true}}></Button> : null}
        </Fragment>
    )
}

export default TextPanel