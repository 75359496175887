import React, {useState} from 'react';
import './input.css'

const Slider = ({init, min, max, setValue})=>{
    const [val, setVal] = useState(init);

    return <input type="range" min={min || 0} max={max || 100} id="myRange" 
                    value={val} 
                    onChange={e=>{
                        setVal(e.target.value);
                        setValue(e.target.value)
                    }} />
}

export default Slider;