import React from 'react';
import {ResponsiveImage } from "../../theme";
import SCQuery from '../../components/helper/SCQuery'

const ImageWithName = ({ title, options}) => {
    return <SCQuery query={"*[_type == 'image_kbi' && title == '"+title+"']{title,'url':image.asset->url}"}>
        {(image)=>{
            image = image[0];
            options = options || {};
            return <ResponsiveImage src={`${image.url}`} options/>
            }}
    </SCQuery>
}


export default ImageWithName

