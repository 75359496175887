import React, {useState} from 'react';
import { useSelector} from "react-redux";
import Calendar from '../../components/other/Calendar'
import MemberList from '../../components/member/MemberList'
import ImageWithName from '../../components/kb/ImageWithName'
import {Line, ContainerSides, Coller, Rower, Section} from "../../theme"
import Button from "../../components/general/Button";
import ProjectList from "../../components/project/ProjectList.jsx";
import Segment from '../../components/general/Segment'
import {Socials} from '../../pages/contact'
import Expand from 'react-expand-animated';
import { Grid } from 'react-styled-flexboxgrid';



const LandingContent = () => {
    const member = useSelector(state => state.data.member);
    const [introExpand, setIntroExpand] = useState(false);
    return(
    <div>
        <br />
        <Rower>
            <Coller marginBottom xs={10} sm={4} xsOffset={1} smOffset={4}>
                <ImageWithName title="logo" />
            </Coller>
        </Rower>
        
        <Segment title="intro"/>
        <Expand open={introExpand}>
            <Segment title="intro2"/>
        </Expand>
        <div onClick={()=>setIntroExpand(!introExpand)} style={{textDecoration: "underline"}} >{introExpand?"Less":"more..."}</div>
        <br/><br/>

        <MemberList notrole="network" />
        <br />
        <ContainerSides>
            <Button text="All members" link="/info" />
            {member && member._id ? <Section><Button text="go to personal page" link='/dashboard' options={{noTop:true}} /><br/><br/></Section> :null}
        </ContainerSides>
        <br /><br />

        <Line />
        <ProjectList title="landingspage" />
        <Line />
        <br />
        <br />
        <ContainerSides>
            <Button text="Art Projects" link="projects" options={{bold:true}}/>
            <br/>
            <Button text="Research & Design" link="research" options={{bold:true}}/>
            <br/>
            <Button text="Education & Advocacy" link="education" options={{bold:true}}/>
            <br/>
            <Button text="All projects" link="overview" options={{bold:true}}/>
        </ContainerSides>
        <br/><br/>
        <Line />
        <Calendar />
        <Line />
        <br/><br/>
        <ContainerSides>
            <Button text="info@seads.network" link="mailto:info@seads.network" options={{external:true}}/>
        </ContainerSides>
        <br/><br/>
        <ContainerSides>
        <Socials />
        </ContainerSides>
        <br/><br/>
    </div>

)};

export default LandingContent