import React, { useState } from "react"
import SCQuery from '../../components/helper/SCQuery'
import Button from '../../components/general/Button'
import {Grid, Row, Col, Title} from '../../theme'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from '../../utilities/toast'

const ConditionalTH = ({content})=>{
    return <th>
        {content?content:"✖"}
    </th>
}

const MemberTable = ()=>{
    const [data, setData] = useState(null);

    const visualizeMember = (data)=>{
        return data.map(member => (
            <tr key={member.firstname + "_" + member.lastname }>
                <th>
                    {member.firstname}
                </th>
                <th>
                    {member.lastname}
                </th>
                <th>
                    {member.email}
                </th>
                <th>
                    <Button text="visit page" link={`/member/${member.slug}`} />
                </th>
                <th>{member.role}</th>
                <ConditionalTH content={member.whatsapp} />
                <ConditionalTH content={member.instagram} />
                <ConditionalTH content={member.facebook} />
                <ConditionalTH content={member.twitter} />
                <ConditionalTH content={member.website} />
            </tr>
        ))
    }

    return <SCQuery query={"*[_type == 'member'] | order(_updatedAt desc) {_id, firstname, preventEmail, lastname, 'avatar':avatar.asset->url, shortbio, longbio, email, 'slug':slug.current, role, email, whatsapp, instagram, facebook, twitter, website}"}>
        {(memberdata)=>{

            if (!memberdata){
                return null;
            }

            memberdata.sort((member, othermember)=>{
                let vals = {
                    coordination: 2,
                    collective: 1,
                    network: 0
                }
                return vals[othermember.role] - vals[member.role]
            })

            return <div style={{width: "100%", maxWidth: "100%", overflowX: "scroll"}}>
                <Row>
                    <Col xs={12} sm={4} ms={4}>
                        <Button text="coordination" onClick={()=>{
                            setData(memberdata.filter(member=>(member.role == "coordination")));
                        }} />
                    </Col>

                    <Col xs={12} sm={4} ms={4}>
                        <Button text="collective" onClick={()=>{
                            setData(memberdata.filter(member=>(member.role !== "network")));
                        }} />
                    </Col>

                    <Col xs={12} sm={4} ms={4}>
                        <Button text="network" onClick={()=>{
                            setData(memberdata);
                        }} />
                    </Col>
                </Row>

                <br/>
                    
                <CopyToClipboard text={(()=>{
                    let rv = "";
                    (data?data:memberdata).forEach(member=>{
                        if (!member.preventEmail){
                            rv += member.email + ", "
                        }
                    })
                    return rv;
                })()}
                    onCopy={()=>{toast('The emails have been copied to your clipboard')}}>
                    <Button text="copy selected emails to clipboard" onClick={()=>{}} options={{external: true}} />
                </CopyToClipboard>

                <CopyToClipboard text={(()=>{
                    let rv = "";
                    (data?data:memberdata).forEach(member=>{
                        rv += `${member.firstname}, ${member.lastname}, ${member.email} \n`
                    })
                    return rv;
                })()}
                    onCopy={()=>{toast('The members have been copied to your clipboard')}}>
                    <Button  text="copy selected members to clipboard" onClick={()=>{}} options={{external: true, noTop: true}} />
                </CopyToClipboard>

                
                
                

                
                

                <br />

             <table >
                <tr>
                    <th>
                        given name
                    </th>
                    <th>
                        name
                    </th>
                    <th>
                        email
                    </th>
                    <th>
                        profile
                    </th>
                    <th>
                        role
                    </th>
                    <th>whatsapp</th>
                    <th>
                        instagram
                    </th>
                    <th>
                        facebook
                    </th>
                    <th>twitter</th>
                    <th>website</th>

                </tr>

                 {visualizeMember(data?data:memberdata)}

            </table>
            </div>
        }}
    </SCQuery>
}

export default MemberTable;