import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import MetaTags from 'react-meta-tags';
import {Grid, Row, Col, Title, Section, SuperTitle, CircleImage, ContainerSides, SubTitle, Wrapper, InlineText, ContainerPadding, ResponsiveImageWithEffect} from '../theme'
import BlockContent from '@sanity/block-content-to-react';
import SCQuery from '../components/helper/SCQuery';
import KB from '../pages/KB'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

const Groovember = ({match})=>{
    const storedata = useSelector(state => state.data);
    const [metaTitle, setMetaTitle] = useState("SEADS / Groovember");
    const [metaDescription, setMetaDescription] = useState("A collaboration between SEADS and Albert Wang")
    const [metaImage, setMetaImage] = useState("https://cdn.sanity.io/images/t39t9igo/production/30d322a87dffa9955cc0ee2ae54245aa81f67d65-552x552.png?w=200")
    const client = useSelector(state => state.data.client);
    const [hasSend, setHasSend] = useState(false);
    const dispatch = useDispatch();

    let kb =(intro)=>{
        if (storedata.taxonomy){
            let rv = null;
            storedata.taxonomy.forEach((tag)=>{
                if (tag.title === "Groovember"){
                 rv = <KB taxonomy={[tag]} search={" "} />
                }
            })
            return rv;
        } else {
            return null;
        }
    } 

    useEffect(() => {
        if (match && match.params && match.params.id && !hasSend && client){
            client.fetch("*[ _id == '" + match.params.id + "'][0]{title, _id, _type, 'thumbnail':thumbnail.asset->url, star, description}")
            .then((kbi)=>{
                dispatch({type: DATA_ACTIONS.SET_CURRENT_KBI, kbi});
                setMetaTitle(kbi.title);
                if (kbi.description && kbi.description.length > 0 && kbi.description[0].children.length > 0){
                    setMetaDescription(kbi.description[0].children[0].text);
                }
                setMetaImage(kbi.thumbnail+"?w=200");
            })
            setHasSend(true);
        }        
    });

    return (
        <Section>
            <Grid >
            <br/>
            <MetaTags>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:title" content={metaImage} />
            </MetaTags>
            <Row center="xs">
                <Col xs={12}>
                    <Title>Groovember</Title>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={8} lg={7}>
                    <SCQuery single={true} query={"*[_type == 'siteSettings'][0]{groovember}"}>
                        {({groovember})=>{
                        return <BlockContent blocks={groovember} style={{lineHeight: "500px"}} />
                        }}
                    </SCQuery>
                    <br/><br/>
                </Col>
        </Row>
        </Grid>
        {kb()}
        </Section>
        )
}

export default Groovember