import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import {Title, InlineText} from '../../theme';
import SCQuery from '../../components/helper/SCQuery'

const Segment = ({ title, showTitle, options}) => {
    return <SCQuery query={"*[_type == 'segment' && title == '"+title+"']{title, Content}"}>
        {(segmentdata)=>{
            segmentdata = segmentdata[0];
            options = options || {};
            let tt = showTitle?<Title>{segmentdata.title} </Title>:"";
            return (
                <div>
                    {tt}
                    <InlineText color={options.color}>
                        <BlockContent blocks={segmentdata.Content} style={{lineHeight: "500px"}} />
                    </InlineText>
                </div>
            )}}
    </SCQuery>
}

export default Segment