import React,{useRef, useEffect, useState} from 'react'
import SCQuery from '../../components/helper/SCQuery'
import CollapsibleContainer from '../../components/helper/CollapsibleContainer';
import Moment from 'react-moment';
import {InlineText, Section, Title, SubTitle, ContainerSides} from '../../theme';
import moment from 'moment-timezone'
import Button from '../../components/general/Button'
import toast from '../../utilities/toast'
import { useSelector} from "react-redux";

const Vimeo = ({id})=>{
    const ref = useRef(null);      
    const [width, setWidth] = useState(null);

    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }
      }, [ref.current, width, setWidth]);

      
    return <Section ref={ref}>
                {id && width?<iframe src={`https://player.vimeo.com/video/${id}`} width={width-1} style={{transform: 'translateX(-1px)'}} height={width/1.8} allow="autoplay; fullscreen" allowFullScreen marginHeight="0" hspace="0" vspace="0" frameBorder="0" scrolling="no"/>:null}
                <br/><br/>
            </Section>
}

export const CommunityNotifier = ()=>{
    const member = useSelector(state => state.data.member);
    const [hasNotified, setHasNotified] = useState(false);

    //implement me again
    return null;

    if (hasNotified || !member){
        return null;
    } else {
        return <SCQuery query={"*[_type == 'communityEvent']{moment, password, title, type, vimeoid}"}>
        {(communityEvents)=>{

        let nextEvent = null;
        let fromnow = 100;

        communityEvents.sort((a, b)=>{
            let [momA, momB] = [new Date(a.moment), new Date(b.moment)]
            if (!hasNotified){
                let from = moment(a.moment).fromNow();
                if (from.indexOf('in')!==-1){
                    let possibleNumber = from.match(/\d+/);
                    if (possibleNumber == null || from.toLowerCase().indexOf('days')==-1){
                        toast(`Next ${a.type[0].type} meeting is ${from}`);
                    } else {
                        let newNumber = parseInt(possibleNumber[0], 10)
                        if (newNumber < fromnow){
                            fromnow = newNumber;
                            nextEvent = a;
                        }
                    }
                }
                return momB-momA;
            }
        })

        if (nextEvent && !hasNotified){
            toast(`Next ${nextEvent.type[0].type} meeting is in ${fromnow} days`);
        }

        return null;

    }}
    </SCQuery>

    }
}

const CommunityMeetings = ()=>{
    const moment_ = moment;
    const initAmount = 10;
    const [amount, setAmount] = useState(null);

    const getBottom = (listamount)=>{
        const getMore = ()=><Button text="more" onClick={()=>setAmount(amount+1)} options={{bold:true, noTop: true}} />
        const getLess = ()=><Button text="less" onClick={()=>setAmount(amount-1)} options={{bold:true, noTop:true}}/>

        if (amount == 1){
            return getMore()
        }else if (amount < listamount){
            return <Section>
                        {getMore()}
                        {getLess()}
            </Section>
        } else if (amount >= listamount-1){
            return getLess()
        } 
    }

    return <SCQuery query={"*[_type == 'communityEvent']{moment, password, title, type, vimeoid}"}>
    {(communityEvents)=>{
        
        
        setTimeout(() => {
            if (amount == null){
                setAmount(Math.min(initAmount, communityEvents.length));
            }
        }, 100);

        communityEvents.sort((a, b)=>{
            let [momA, momB] = [new Date(a.moment), new Date(b.moment)]
            return momB-momA;
        })

        return <Section >{communityEvents.map(({moment, password, title, type, vimeoid}, index)=>{
            return (index<amount? <CollapsibleContainer key={title} title={moment_(moment).format('MMMM Do YYYY, HH:mm')} noPadding noContainer notBold hideTitleTop >
                    <Section style={{width: "100%", textAlign: "center", padding: "1px"}}>
                        <Title> {title} </Title>
                        <SubTitle>{`${type[0].type} - ${moment_(moment).fromNow()}`}</SubTitle>
                        <ContainerSides>
                            {vimeoid?<Section><b>Password:&nbsp;</b>{password}<br/><br/></Section>
                            :<Section>
                                <Button text="go to meeting" link={type[0].type=="collective"?"https://meet.jit.si/seadscollective":"https://meet.jit.si/seadscommunity"} options={{external:true}} />    
                            </Section>}
                        </ContainerSides>
                        {vimeoid?<Vimeo id={vimeoid} />:<Section><br/></Section>}
                    </Section>
            </CollapsibleContainer> : null )
        })}
        {getBottom(communityEvents.length)}
        </Section>
    }}
    </SCQuery>
}

export default CommunityMeetings;