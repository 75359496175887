import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { Title, Rower, Coller, Grid, Text, Line, InlineText, Section} from "../theme";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import ImageWithName from '../components/kb/ImageWithName'
import KB from '../pages/KB'
import MetaTags from 'react-meta-tags';


const NCov = ({match}) => {
    document.title = "SEADS / COVID-19 Tech" ;
    const storedata = useSelector(state => state.data);
    const client = useSelector(state => state.data.client);
    const dispatch = useDispatch();
    const [hasSend, setHasSend] = useState(false);
    const [metaTitle, setMetaTitle] = useState("SEADS / COVID-19 Tech");
    const [metaDescription, setMetaDescription] = useState("The SEADS collective has put together this directory to help you identify tech projects that might make a difference in your community.")
    const [metaImage, setMetaImage] = useState("https://cdn.sanity.io/images/t39t9igo/production/e68ab67f3ba57a9ac7cbf5c2354712183020842b-960x960.jpg?w=200")


    useEffect(() => {
        if (match && match.params && match.params.id && !hasSend && client){
            client.fetch("*[ _id == '" + match.params.id + "'][0]{title, _id, _type, 'thumbnail':thumbnail.asset->url, star, description}")
            .then((kbi)=>{
                dispatch({type: DATA_ACTIONS.SET_CURRENT_KBI, kbi});
                setMetaTitle(kbi.title);
                if (kbi.description && kbi.description.length > 0 && kbi.description[0].children.length > 0){
                    setMetaDescription(kbi.description[0].children[0].text);
                }
                setMetaImage(kbi.thumbnail+"?w=200");
            })
            setHasSend(true);
        }        
    });

    let title = (<Rower>
        <Coller marginBottom xs={3} sm={2} md={1} style={{paddingLeft: "30px"}}>
        <Link to='/'>
            <ImageWithName title="logoseads" />
        </Link>
    </Coller>
    <Coller xs={8}>
        <Title align="left" style={{width:"100%"}}>SEADS Database of COVID-19 Technologies</Title>
    </Coller></Rower>)

    let text = <Coller xs={12}>
                    <Rower>
                        <Coller xs={12} md={6} lg={4}>
                            <InlineText align="left" style={{paddingTop: "0px"}}>
                                In response to the COVID-19 pandemic, many groups and individuals (from established university research laboratories, to concerned designers and hackers) have been developing a broad range of technologies to address the many crises surrounding the pandemic, such as the unavailability of PPEs in many countries. 
                                The SEADS collective has put together this directory to help you identify tech projects that might make a difference in your community. <br/><br/>While we pick which projects to list in this directory, we are neither offering medical advice through this directory nor evaluating these interventions in detail.
                            </InlineText>
                        </Coller>
                        <Coller xs={true} md={false}>
                            <br/>
                        </Coller>
                        <Coller xs={12} md={6} lg={4}>
                            <InlineText align="left" style={{paddingTop: "0px"}}>
                                We strongly recommend that you refer to organisations that systematically evaluate evidence, such as the 
                                &nbsp;<a href="https://www.cebm.net/oxford-covid-19/" target="_blank" rel="noopener noreferrer" style={{textDecoration: "underline"}}>Oxford COVID-19 Evidence Service</a>, <a href="https://www.evidenceaid.org/evidence-collections/" style={{textDecoration: "underline"}} rel="noopener noreferrer" target="_blank">Evidence Aid</a>, <a href="https://www.cochrane.org/coronavirus-covid-19-cochrane-resources-and-news" style={{textDecoration: "underline"}} rel="noopener noreferrer" target="_blank">Cochrane Resources</a> and <a href="https://covid-evidence.org/" style={{textDecoration: "underline"}} rel="noopener noreferrer" target="_blank">COVID-Evidence</a> before making decisions on how to make use of these projects. As a lot of these DIY equipment are recently developed, not all of them have gone through strict regulatory procedures and therefore needs to be used under appropriate protocols and policies and evaluated for continuous use.											
                            </InlineText>
                            <InlineText align="left" >
                                If you would like us to list your project in this directory, or if you would like us to amend your listing, please email  <a style={{textDecoration: "underline"}} href="mailto:contact@seads.network">covid19@seads.network</a>. <br/><br/> This project was realised with the support of <a href={"https://www.up.edu.ph/"} target="_blank" rel="noopener noreferrer" style={{textDecoration: "underline"}} >the University of the Philippines</a>. 										
                            </InlineText>
                        </Coller>
                    </Rower>
                </Coller>
        

    let intro = <Rower>    
                    {title}
                    {text}
                    
                </Rower>;
    

    let kb =()=>{
        if (storedata.taxonomy){
            let rv = null;
            storedata.taxonomy.forEach((tag)=>{
                if (tag.title == "Covid-19"){
                 rv = <KB taxonomy={[tag]} intro={intro} title={title} text={text} />
                }
            })
            return rv;
        } else {
            return null;
        }
    } 
    
    return <Section>
        <MetaTags>
                <title>SEADS / COVID-19 Tech</title>
                <meta name="description" content={metaDescription}/>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:image" content={metaImage} />
            </MetaTags>
        {kb()}
    </Section>
        
        
}

export default NCov;

{/* <Grid style={{margin: "0", width: "auto"}}>
            <br /><br /><br /><br />
            {image}
            <br />
            <Rower >
                {kb()}
                {text}
            </Rower>
            <br/><br/>
        </Grid> */}
