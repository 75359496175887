import React, {useState} from 'react';
import Octicon, { getIconByName } from '@primer/octicons-react'
import styled from 'styled-components';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../../reducers/DataReducer"
import {IconContainer, PanelTitle, InlineText, Section} from "../../theme"
import {margins} from "../../theme/config.json";
import Button  from "../../components/general/Button"

export const KbiPanelContent = styled.span`
  padding-left: ${margins.text.left};
`;

export const KbiTable = styled.table`
  border: 3px solid black;
  border-collapse: collapse;
`

export const KbiTd = styled.td`
  border: 3px solid black;
  border-collapse: collapse;
  text-align: left;    
`

const KbiList = (props) => {
    const storedata = useSelector(state => state.data);
    const dispatch = useDispatch();
    const [amountShow, setAmountShow] = useState(props.limit);


    let clicker = (kbi)=>{
        if (props.saveStore){
            dispatch({type: DATA_ACTIONS.SET_CURRENT_KBI, kbi: kbi});
        } else {
            let openv = null;
            switch (kbi._type) {
                case "link_kbi":
                    openv = kbi.link;
                    break;
                case "extended_url_kbi":
                    openv = kbi.projectlink
                    break;
                default:
                    break;
            }
            
            if (openv){ 
                if (!(openv.includes("http://")||openv.includes("https://"))){
                    openv = "http://"+openv;
                }
                window.open(openv);
            }
            
        }
    }

    let checkIfSlecedInStore = (element)=>{
        return storedata && storedata.currentKBI && (element._id === storedata.currentKBI._id)
    }

    let children = props.data.map((element, idx, arr) => (
        <tr key={element.title+idx} onClick={() => clicker(element)} style={{ cursor: "pointer" }}>
            {element.thumbnail? 
                <td style={{backgroundImage: "url("+element.thumbnail+"?w=50)", width: "50px", height: "50px", backgroundSize: "100% auto", borderBottom: '3px solid black'}} ></td>
                :
                <KbiTd style={{backgroundColor: 'black', textAlign: "center", width: '30px', borderBottom: idx < arr.length-1?'3px solid black':'null'}}>
                    <IconContainer>
                        <Octicon size='medium' verticalAlign='middle' icon={getIconByName( (element.icoon)? element.icoon.link || "link" : "link")} />
                    </IconContainer>
                </KbiTd> }
            <KbiTd style={{paddingLeft: '10px', background: checkIfSlecedInStore(element)?"black":"white"}}>
                <InlineText color={checkIfSlecedInStore(element)?"white":"black"}>{element.title}</InlineText>
                
            </KbiTd>
        </tr>
    ))
    
    let originalLength = children.length;
    if (props.limit){
        children = children.filter((child, idx)=>idx<amountShow);
    }
    

    return (<Section>
        <KbiTable style={{width:'100%', marginTop:'11px'}}>
            <tbody>
                {props.title?<tr><th colSpan="2"><PanelTitle style={{paddingLeft : "10px"}} noBorderBottom={true}>{props.title}</PanelTitle></th></tr>:null}
                {children}
            </tbody>
        </KbiTable>
        {(props.limit  && (children.length > 0) && (amountShow < originalLength-1))?<Section><br/><Button text="more" onClick={()=>{setAmountShow(amountShow+props.limit)}} /><br/><br/></Section>:null}
        
        </Section>
    )
}
export default KbiList;