
import React, {useState} from 'react';
import './input.css'

const Toggle = ({init, setValue})=>{
    const [val, setVal] = useState(init);

    return <React.Fragment>
        <label className="switch">
        <input type="checkbox" type="checkbox" defaultChecked={val} onChange={()=>{
            setVal(!val);
            setValue(!val);
        }} />
        <span className="slider"></span>
        </label> 
        </React.Fragment>
}

export default Toggle;