import React, { useRef, useState, useEffect } from 'react';
import { useSelector} from "react-redux";
import SCQuery from '../components/helper/SCQuery'
import {Section, SubTitle, Grid, Row, Col} from '../theme'
import {TagMenu} from '../pages/KB'
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend,PieChart, Pie,
} from 'recharts';
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBXyPzWARtV2zGWXC1DKsFskMLxFfZGvtA");
Geocode.setLanguage("en");

const color = "#3801FF"


const PiChart = ({data})=>{
    const [width, setWidth] = useState(null);
    const ref = useRef(null);      

    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }
      }, [ref.current, width, setWidth]);
    return (
        <Section style={{width: "100%"}} ref={ref}>
        <PieChart width={width} height={400}>
        <Pie data={data} dataKey="a" cx={width/2} cy={width/3} outerRadius={width/4} fill={color} />
        <Tooltip />
        <Legend />
      </PieChart>
      </Section>
    );
}


const Chart = ({data})=>{
    const [width, setWidth] = useState(null);
    const ref = useRef(null);      

    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }
      }, [ref.current, width, setWidth]);

    return (
        <Section style={{width: "100%"}} ref={ref}>
            <BarChart
                    width={width}
                    height={300}
                    data={data}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="a" fill={color} />
            </BarChart>
        </Section>
    );
  }


const CovidInsights =  ()=>{

    const taxonomy = useSelector(state => state.data.taxonomy);
    const [countries, setCountries] = useState({});

    return <SCQuery query={'*[_type == "extended_url_kbi"]{tags[]->{title}, location}'}>
        {(entries)=>{
            
            const allTags = {};
            const allLocations = [];

            entries.forEach((entry)=>{

                if (entry.location){
                    allLocations.push(entry.location);
                }

                if (entry.tags && Array.isArray(entry.tags)){
                    entry.tags.forEach((tag)=>{
                        if (allTags[tag.title]){
                            allTags[tag.title]++;
                        } else {
                            allTags[tag.title] = 1;
                        }
                    })

                }
            })

            let allData = [];
            for (var property in allTags) {
                if (allTags.hasOwnProperty(property)) {
                  allData.push({name: property, a: allTags[property]})
                }
            }

            allData.sort((a, b)=>a.a- b.a)


            let levels = [];

            const populateLevels = (level, element)=>{

                const d = {
                    name: element.title,
                    a: allTags[element.title]
                }

                if (levels[level]){
                    levels[level].push(d);
                } else {
                    levels[level] = [d];
                }

                if (element.children){
                    element.children.forEach((child)=>{
                        populateLevels(level+1, child);
                    })
                }
            }


            taxonomy.forEach((tax)=>{
                populateLevels(0, tax);
            })

            levels.forEach((level)=>level.sort((a, b)=>a.a-b.a))

            levels = levels.map((level, idx)=>{
            console.log("level: "+idx, level)
            return <Section key={idx}>
                <SubTitle>Level {levels.length-(idx+1)}</SubTitle>
                <Chart data={levels[levels.length-(idx+1)]}  />
            </Section>})

            if (Object.keys(countries).length === 0 && countries.constructor === Object){
                allLocations.forEach((location)=>{

                    let counter = 0;
                    let fetcher = ()=>{
                        return Geocode.fromLatLng(location.lat, location.lng)
                    };
    
                    fetcher()
                    .then(response=>{
                        let found = false;
                        response.results[0].address_components.forEach((component)=>{
                            if (component.types.indexOf('country')!==-1 && !found){
                                let name = component.long_name;
                                found = true;
                                if(countries[name]){
                                    countries[name]++;
                                    setCountries(JSON.parse(JSON.stringify(countries)));
                                } else {
                                    countries[name] = 1;
                                    setCountries(JSON.parse(JSON.stringify(countries)));
                                }
                            }
                        })
                    }, error => {
                        if (counter < 5){
                            counter++;
                            setTimeout(() => {
                                fetcher();
                            }, 100);
                        } else {
                            console.log('failed');
                        }
                      })
                })
            }

            const countrydata = [];
            for (var property in countries) {
                if (countries.hasOwnProperty(property)) {
                    countrydata.push({name: property, a: countries[property]})
                }
            }

            countrydata.sort((a, b)=>b.a-a.a)

            return <Grid>
                <Row center="xs">
                    <Col xs={12} sm={10} md={6} lg={5}> 
                            {levels}
                            <SubTitle>All tags</SubTitle>
                            <Chart data={allData}  />
                        </Col>
                        <Col xs={12} sm={10} md={6} lg={5}> 
                        <SubTitle>Countries</SubTitle>
                        <Chart data={countrydata} />
                        <br/><br/>
                        {(taxonomy && taxonomy.length > 0)?<TagMenu taxonomy={taxonomy} initialOpen={true} onChangeTags ={(()=>{})} unselectTag={(()=>{})} />:null}
                    </Col>
                </Row>
            </Grid>
        }}
        </SCQuery>
}

export default CovidInsights;


