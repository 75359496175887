import React, { useState, useEffect } from 'react';
import { useSelector} from "react-redux";
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { ClickIcon, Row, ListItem, Title, Text, CircleImage, OnlyLarge, OnlySmall, UnderLine } from '../../theme';
import {fonts} from '../../theme/config.json'
import close from '../../assets/icons/close-sketch.svg'; //change icon
import menu from '../../assets/icons/menu-sketch.svg'; //change icon
import Memberlogin from '../../components/member/Memberlogin'
import {LeftIcon, RightIcon} from '../../theme/icons'
import { withRouter } from 'react-router-dom'
import {TextButton} from "../general/Button";

export const NavContainer = styled.div`
  margin-left: 4px;
`

export const NavTitle = styled(Title)`
  font-size: 1.3em;
  margin-left: 30px;
`;

export const NavBar = styled.div`
  align-items: center;
  display: flex;
  background: white;
  border-bottom: 3px solid black;
  border-top: 3px solid black;
  justify-content: flex-start;
  height: 35px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
`;

export const MenuPanel = styled.div`
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background: white;
  border: 3px solid black;
  border-top: 0px;
  padding: 4px;
  position: fixed;
  left: 38px;
  top: 40px;
  width: 200px;
  transform: ${props => props.open ? 'translateY(-3px)' : 'translateY(-600px)'};
  z-index: 98;
`;

export const MenuRow = styled(Row) `
  padding: 3px;
  margin: 4px;
  margin-bottom: 6px;
`

export const MenuLine = styled.div`
    border-bottom: 3px solid black;
`

export const MenuRowLineless = styled(MenuRow) `
  border-bottom: 0px;
`

export const MenuItem = styled(ListItem)`
  display: flex;
  cursor: pointer;
`

const MenuTitleContainer = styled.div`
    font-weight: ${fonts.weight.boldW};
    margin-left: 20px;
`

let NameDisplay = ()=>{
    const member = useSelector(state => state.data.member);
    const client = useSelector(state => state.data.client);

    if (member){
        return <NavLink to="/dashboard" style={{position: "absolute", right: "30px"}}>
                    <p style={{float: "left", transform: "translate(0, -5px)", textAlign: "center"}}>
                    <Text style={{borderLeft: "3px solid black", fontSize: "13px", float: "left", transform: "scaleY(1.4)"}} > &nbsp;</Text>
                        <Text>
                            {member.firstname}
                        </Text>
                        <CircleImage src={client.squareImage(member.avatar, 25)} width="25px" style={{transform: "translateY(8px)"}} />
                    </p>
                    
                </NavLink>
    } else {
        return null;
    }
}

const MemberMenu = ()=>{
    const member = useSelector(state => state.data.member);
    if (!member){
        return null;
    } else {
        return <React.Fragment>
                <MenuRow>
                    <NavLink to='/dashboard' style={{textDecoration: "none"}}>
                        <MenuItem>Dashboard</MenuItem>
                    </NavLink>
                </MenuRow>
            <MenuLine />
            <MenuRow>
                    <NavLink to='/stuff' style={{textDecoration: "none"}}>
                        <MenuItem>Stuff</MenuItem>
                    </NavLink>
                </MenuRow>
            <MenuLine />
            <MenuRow>
                    <NavLink to='/lab' style={{textDecoration: "none"}}>
                        <MenuItem>Lab</MenuItem>
                    </NavLink>
                </MenuRow>
            <MenuLine />
            {/* <MenuRow>
                    <NavLink to='/chapters' style={{textDecoration: "none"}}>
                        <MenuItem>Chapters</MenuItem>
                    </NavLink>
                </MenuRow>
            <MenuLine /> */}
        </React.Fragment> 
    }
}

const Nav = ({history})=>{
    const [menuOpen, setMenuOpen] = useState(false);
    const [loc, setLoc] = useState(null);
    const toggle = ()=>{setMenuOpen(!menuOpen)};
    const currentProject = useSelector(state=>state.data.currentProject);
    const currentChapter = useSelector(state=>state.data.currentChapter);

    useEffect(() => {
        let path = window.location.pathname;

        const checkPath = ()=>{
            if (loc !== path){
                setLoc(path);
            }
        }

        checkPath();
        let listener = history.listen(() => {
            checkPath();
        })

        return listener;
    });

    

    const getTitle = ()=>{
        // <OnlyLarge>
        //     {/* {this.props.history.length>2?<span><LeftIcon style={{transform: "translateY(3px)"}} onClick={() => this.onBack()}/>&nbsp;&nbsp;&nbsp;</span>:null} */}
        // </OnlyLarge>
        
        const titleSmall = "SEADS";
        const titleLarge = "SPACE ECOLOGIES ART AND DESIGN";

        let pointer = null;
        let content = null;
        const SEADSPOINTER = <TextButton text="SEADS" link="/" />
        const SEADSsmallToggle = <OnlySmall onClick={toggle}>{titleSmall}</OnlySmall>

        if (loc!=="/"){
            pointer = <OnlyLarge>
            <span><LeftIcon style={{transform: "translateY(3px)"}} onClick={() => history.goBack()}/>&nbsp;&nbsp;&nbsp;
            </span>
        </OnlyLarge>
        }

        if (loc === "/projects"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / projects`}
            </React.Fragment>
        } else if (loc && loc.indexOf("project")!==-1 && currentProject){
            let middle = "project";
            let target = "/projects";

            if (currentProject.type === "education"){
                middle = "education";
                target = "/education";
            }

           content= <React.Fragment>
                        {SEADSPOINTER} / &nbsp; 
                        <TextButton text={middle} link={target} /> / 
                        {` ${currentProject.slug}`}
           </React.Fragment>
        }else if (loc === "/chapters"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / chapters`}
            </React.Fragment>
        } else if (loc && loc.indexOf("stuff")!==-1){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / stuff`}
            </React.Fragment>
        } else if (loc && loc.indexOf("groovember")!==-1){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / groovember`}
            </React.Fragment>
        
        } else if (loc && loc.indexOf("chapter")!==-1 && currentChapter){
            let middle = "chapters";
            let target = "/chapters";

           content= <React.Fragment>
                        {SEADSPOINTER} / &nbsp; 
                        <TextButton text={middle} link={target} /> / 
                        {` ${currentChapter.title}`}
           </React.Fragment>
        }else if (loc === "/dashboard"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / dashboard`}
            </React.Fragment>
        } else if (loc === "/lab"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / LAB`}
            </React.Fragment>
        } else if (loc === "/education"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / education`}
            </React.Fragment>
        } else if (loc === "/research"){
            content= <React.Fragment>
                         {SEADSPOINTER}
                         {` / research`}
            </React.Fragment>
        } else {
            content = <React.Fragment>
                        {SEADSsmallToggle}
                        <OnlyLarge onClick={toggle}>
                            {titleLarge}
                        </OnlyLarge>            
            </React.Fragment>
        }

        return <React.Fragment>
            <OnlyLarge>
                {pointer}{content}
            </OnlyLarge>
            <OnlySmall>
                {SEADSsmallToggle}
            </OnlySmall>
        </React.Fragment>
    }

    return  <span>
                <NavBar>
                <NavContainer>
                    <OnlySmall>
                        <ClickIcon src={menuOpen} onClick={toggle} />
                    </OnlySmall>
                    <OnlyLarge onClick={toggle} >
                        <MenuTitleContainer>
                            <div style={{width: "50px", float: "left", transform: "translateY(5px)"}}>
                                {!menuOpen ? "MENU" : "CLOSE"}
                            </div>
                            <Text style={{borderRight: "3px solid black", fontSize: "13px", float: "right", transform: "scaleY(1.18)"}} > &nbsp;</Text>
                        </MenuTitleContainer>
                    </OnlyLarge>
                    </NavContainer>
                    <NavTitle>
                        {getTitle()}
                    </NavTitle>
                    <NameDisplay />
                </NavBar>
                <MenuPanel open={menuOpen}>
                    <MenuRow>
                        <NavLink to='/' style={{textDecoration: "none"}}>
                            <MenuItem>Home</MenuItem>
                        </NavLink>
                    </MenuRow>
                    <MenuLine />
                    <MenuRow>
                        <NavLink to='/projects' style={{textDecoration: "none"}}>
                            <MenuItem >Art</MenuItem>
                        </NavLink>
                    </MenuRow>
                    <MenuLine />
                    <MenuRow>
                        <NavLink to='/research' style={{textDecoration: "none"}}>
                            <MenuItem >Research</MenuItem>
                        </NavLink>
                    </MenuRow>
                    <MenuLine />
                    <MenuRow>
                        <NavLink to='/education' style={{textDecoration: "none"}}>
                            <MenuItem>Education</MenuItem>
                        </NavLink>
                    </MenuRow>
                    <MenuLine />
                    <MenuRow>
                        <Memberlogin />
                    </MenuRow>
                    <MenuLine />
                    <MemberMenu />
                    <MenuRow>
                        <NavLink to='/info' style={{textDecoration: "none"}}>
                            <MenuItem>Info</MenuItem>
                        </NavLink>
                    </MenuRow>
                </MenuPanel>
                <br/><br/>
            </span>
}
export default withRouter(Nav)