import React from 'react';
import { Coller, MainContainer, Rower } from '../theme';
import SCQuery from '../components/helper/SCQuery'
import ProjectTile from '../components/project/ProjectTile';
import MetaTags from 'react-meta-tags';

const Portfolio = (props) => 
        <div>
            <MetaTags>
                <title>{"SEADS / Projects"}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS / Projects"} />
                <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
        <SCQuery single query={'*[_type == "projectgroup" && title == "portfolio"][0]{projects[]->{_type, title, _id, "slug":slug.current, projects, "cover":cover->image.asset->url}}'}>
            {/* *[_type=="hyperproject" || _type=="project" && count(*[_type == "hyperproject" && references(^._id)]) == 0]{_type, title, _id, "slug":slug.current, projects, "cover":cover->image.asset->url}' */}
            {(data)=>{
                let hyperandprojects = data.projects;
                hyperandprojects = hyperandprojects.map((project) => {
                    let sublink = project._type;
                    return (
                        <Coller marginBottom key={`portfolio-${project.slug}`} lg={3} md={4} sm={4} xs={12}>
                            <ProjectTile img={`${project.cover}?w=400`} link={`/${sublink}/${project.slug}`} title={project.title} container/>
                        </Coller>
                    )})
                return (
                    <MainContainer>
                        <Rower center="xs">
                        {hyperandprojects}
                        </Rower>
                </MainContainer>
                )
            }}
        </SCQuery>
    </div>
   
export default Portfolio;
