import React, {useEffect} from 'react';
import Segment from '../../components/general/Segment';
import {Footer as FooterContainer} from '../../theme'
import styled from 'styled-components';

export const FixedContainer = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
`

export default ()=><FooterContainer>
    <div style={{padding: "20px"}}>
        <Segment title="footer" options={{color:'white'}}/>
    </div>
    </FooterContainer>