import React from 'react';
import Media from 'react-media';
import { LandingsPanel, Container } from '../theme';
import { medias } from '../theme/config.json';
import LandingsGallery from '../components/landing/Landingsgallery'
import LandingContent from '../components/landing/LandingContent'
import MetaTags from 'react-meta-tags';

//STODO: fix member list
//STODO: redesign landsingsgallery + PANEL

// {/* <Calendar /> SFIXME */}
// <MemberList></MemberList>
// {/* <LandingsGallery title='landing'></LandingsGallery> SFIXME*/}

const Home = () => (
    <div>
        <MetaTags>
                <title>{"SEADS "}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS"} />
                <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
        <Media query={medias.mdandup}>
            <LandingsGallery title='landingspage' />
        </Media>
        <Media query={medias.mdandup}>
            <LandingsPanel>
                <LandingContent />
            </LandingsPanel>
        </Media>
        <Media query={medias.smallerthanmd}>
            <Container>
                <LandingContent />
            </Container>
        </Media>
    </div>
)

export default Home;
