import React from 'react';
import { Link } from 'react-router-dom';
import { SquareImageWithEffect, Container } from '../../theme';
import Button from '../../components/general/Button';

const ProjectTile = ({ link, title, img, container }) => (
    <Container size={container?null:"0px"}>
            <Link key={title} to={link}>
            <SquareImageWithEffect width='100%' img={img} />         
            </Link>
            <br /><br />
            <Button link={link} text={title} />
    </Container>
)

export default ProjectTile;