import React from 'react';
import SCQuery from '../../components/helper/SCQuery'
import ProjectTile from '../../components/project/ProjectTile';
import {Row, Col} from '../../theme'

const MemberProjects = ({member})=>{
    return <SCQuery query={'*[_type=="project" && "'+member._id+'" in members[]._ref] | order(_createdAt desc) {title, _type, "slug":slug.current, "cover":cover->image.asset->url}'}>
    {(projects)=><Row center="xs">
            {projects.map((project) => 
               <Col xs={10} xsOffset={1} smOffset={0} sm={6} md={4} lg={3} key={`portfolio-${project.slug}`}>
                   <ProjectTile img={`${project.cover}`} link={`/${project._type}/${project.slug}`} title={project.title} container/>
               </Col>    
           )}
        </Row>
    }</SCQuery>
}

export default MemberProjects;