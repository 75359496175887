import React, {useRef, useState, useEffect} from 'react';
import Sketch from "react-p5";
import {Row, Col, SubTitle, Wrapper, Container, InlineText} from '../../theme'
import Slider from '../../components/helper/SliderInput'
import Toggle from '../../components/helper/ToggleInput'
import Button from '../../components/general/Button'
import Chance from 'chance'
import jsonxml from 'jsontoxml'

const PixelVisualizer = ({width, resolution, seed, code})=>{
    const ref = useRef(null);

    const setup = (p5, canvasParentRef) => {
        p5.createCanvas(width, width).parent(canvasParentRef); 
    };

    const draw = (p5) => {
        if (!code){
            return null;
        }

        p5.background("red");
        p5.randomSeed(seed)
        const scaler= p5.width/resolution;
        for (let x = 0; x < resolution; x++) {
            for (let y = 0; y < resolution; y++) {
                let val = code[x*resolution+y];
                p5.fill(val);
                p5.rect(x * scaler, y*scaler, scaler, scaler);
            }
        }
    }
    return <div ref={ref}>
        <Sketch setup={setup} draw={draw} />
    </div> 
    
}

const SPG = ()=>{
    
    const canvasSize = 100;
    const maxCode = 5;
    const [minResolution, maxResolution] = [2, 10];
    const [amntCode, setAmntCode] = useState(3);
    const [resolution, setResolution] = useState(3);
    const [mortMin, setMortMin] = useState(50);
    const [mortMax, setMortMax] = useState(150);
    const [repMin, setRepMin] = useState(50);
    const [repMax, setRepMax] = useState(150);
    const [seeds, setSeeds] = useState(null);
    const [values, setValues] = useState(null);
    const [originalCode, setOriginalCode] = useState(null);
    const [modifiedCode, setModifiedCode] = useState(null);
    const [enableCustom, setEnableCustom] = useState(false);
    const [customModification, setCustomModification] = useState("");
    const chance = new Chance();

    const paramsizes = {
        key: {xs: 3, md: 2},
        slider: { xs: 6, md: 4},
        post: {xs: 3, md: 6}
    }

    const pixelVisualizers = new Array(maxCode).fill(0).map((x, index)=>{
        return <div key={index}>
                <PixelVisualizer width={canvasSize} code={originalCode?originalCode[index]:null} resolution={resolution} seed={seeds?seeds[index]:0} mort={0} repr={100} />
                <br/>
            </div>})
    
    const pixelVisualizersEdit = new Array(maxCode).fill(0).map((x, index)=>{
        return <div key={index}>
                <PixelVisualizer width={canvasSize} code={modifiedCode?modifiedCode[index]:null} resolution={resolution} seed={seeds?seeds[index]:0} mort={values?values[index].mort:0} repr={values?values[index].repr:0} />
                <br/>
            </div>})

    useEffect(() => {
        if (!seeds){
            populateSeeds();
        }
        
        if ( seeds && !values){
            populateValues();
        }

        if (seeds && values && !originalCode){
            populateCode();
        }

    });

    const populateSeeds = ()=>{
        let arr = []
        
        for (let index = 0; index < maxResolution; index++) {
            let val = Math.floor(Math.random()*1234);
            arr[index] = val;
        }
        setSeeds(arr);
        setOriginalCode(null);
        setModifiedCode(null);
    }

    const populateCode = ()=>{
        let code = []
        let modifiedCode = []

        for (let index = 0; index < maxResolution; index++) {
            const chance = new Chance(seeds[index]);
            code[index] = new Array(maxResolution*maxResolution).fill(0).map(()=>{
                return Math.floor(chance.floating({ min: 0, max: 255 }))
            })
            modifiedCode[index] = new Array(maxResolution*maxResolution).fill(0).map((v, index2)=>{

                let val;

                if (enableCustom){
                    let modified = `
                        let val = ${code[index][index2]}; 
                        let mort = ${values[index].mort};
                        let repr = ${values[index].repr};
                        ${customModification}; 
                        val = Math.floor(val);
                        val;`
                        try {
                            val = eval(modified);        
                        } catch (error) {
                            val = 0;
                            setCustomModification(error);
                            console.log(error);
                            return 0;
                        }
                } else {
                    val = code[index][index2] + values[index].mort%255;
                    val *= values[index].repr / 100;
                    val = Math.min(val, 255);
                    val = Math.max(val, 0);
                    val = Math.floor(val);
                }

                return val;
            })
        }
        setOriginalCode(code);
        setModifiedCode(modifiedCode);

    }

    const populateValues = ()=>{
        let arr = []
        for (let index = 0; index < maxResolution; index++) {
            arr[index] = {
                mort: Math.floor(chance.floating({ min: mortMin, max: mortMax })),
                repr: Math.floor(chance.floating({ min: repMin, max: repMax }))
            }
        }
        setValues(arr);
        setOriginalCode(null);
    }
    
    const getPixelVisualizers = (arr)=>{
         return arr.filter((pv, index)=>index<amntCode)
    }

    const defaultParameter = (key, val, min, max, setValue)=><Row>
        <Col xs={paramsizes.key.xs} md={paramsizes.key.md}>
            {key}
        </Col>
        <Col xs={paramsizes.slider.xs} md={paramsizes.slider.md}>
            <Slider init={val} min={min} max={max} setValue={(v)=>{setValue(v)}} />
        </Col>
        <Col xs={paramsizes.post.xs} md={paramsizes.post.md}>
            {val} / [ {min} - {max} ]
        </Col>
    </Row>

    const downloadTxtFile = (json) => {
        let code = json?originalCode:originalCode.map((segment, i)=>{
            return segment.map((val, i2)=>{
                return {
                    value: val
                }
            })
        })

        let mcode = json?modifiedCode:modifiedCode.map((segment, i)=>{
            return segment.map((val, i2)=>{
                return {
                    value: val
                }
            })
        })

        let representation = {
            date: new Date(),
            bagAmount: amntCode,
            sampleAmount: resolution,
            amountOfDataPoints: amntCode*resolution*resolution,
            data: values,
            original: code.filter((pv, index)=>index<amntCode),
            modified: mcode.filter((pv, index)=>index<amntCode),
            allOriginal: [],
            allModified: [],
            customModification: enableCustom,
            modification: customModification

        }

        code.forEach((element, index) => {
            representation.allOriginal = representation.allOriginal.concat(element);
            representation.allModified = representation.allModified.concat(mcode[index]);
        });

        
        if (!json){
            representation.allOriginal = null;
            representation.allModified = null;
        }

        let content = json?JSON.stringify(representation):jsonxml(representation)
        if (!json){
            content = "<data>"+content+"</data>"
        }

        const element = document.createElement("a");
        const file = new Blob([content], {type: 'text/'+json?"JSON":"XML"});
        element.href = URL.createObjectURL(file);
        element.download = `EoE-code.${json?"json":"xml"}`
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
  }

    if (!seeds || !values || !originalCode || !modifiedCode){
        return null;
    } else {
        return <React.Fragment>
        <Row>
            <Col xs={12}>
                <SubTitle>Parameters</SubTitle>
                {defaultParameter("Bag amount", amntCode, 1, maxCode, setAmntCode)}
                {defaultParameter("Sample amount", resolution, minResolution, maxResolution, setResolution)}
                {defaultParameter("Mortality (min)", mortMin, 0, 200, setMortMin)}
                {defaultParameter("Mortality (max)", mortMax, 0, 200, setMortMax)}
                {defaultParameter("Reproduction (min)", repMin, 0, 200, setRepMin)}
                {defaultParameter("Reproduction (max)", repMax, 0, 200, setRepMax)}
                <br/>
                <Row>
                    <Col xs={paramsizes.key.xs} md={paramsizes.key.md}>
                        &nbsp;
                    </Col>
                    <Col xs={paramsizes.slider.xs} md={paramsizes.slider.md}>
                        <Button text="Randomize Seed" onClick={()=>{populateSeeds()}} options={{noBottom:true}}/>
                        <Button text="Randomize Values" onClick={()=>{populateValues()}} />
                    </Col>

                    <Col xs={12}>
                        <br/><br/><br/>
                        <SubTitle>Modification strategy</SubTitle>
                        <Row>
                            <Col xs={4} md={2}>
                                Enable custom
                            </Col>
                            <Col xs={8}>
                                <Toggle init={enableCustom} setValue={setEnableCustom} />
                            </Col>
                            <br/><br/><br/>
                        </Row>
                        

                    {enableCustom? 
                    <React.Fragment>
                    <Col xs={12} md={6} lg={4}>
                            Write your own modifier in javascript; you have access to the variable "val" which will have a value between [0-255] and mort [0-200] and repr [0-200]
                        <br/><br/>
                        <textarea style={{width: "100%", height: "100px"}} id="noter-text-area" name="textarea" value={customModification} onChange={(e)=>setCustomModification(e.target.value)} />

                        </Col> 
                        <Col xs={12} md={6} lg={4}>
                            <Button text="update" onClick={()=>{setOriginalCode(null)}} />
                        </Col>
                        </React.Fragment>:
                    <React.Fragment>
                        <code>
                        let val = (random(255) + mort)%255; <br/>
                        val = val*repr/100.0; <br/>
                        val = Math.min(val, 255); <br/>
                        val = Math.max(val, 0);<br/>
                        </code>
                    </React.Fragment>
                    }
                    </Col>
                </Row>
            </Col>
        </Row>
        <br/><br/><br/>
        <Wrapper>
            <Container>
                <Row >
                    <Col xs={12} md={3}>
                        <b>original code</b>
                        <br/><br/>
                        {getPixelVisualizers(pixelVisualizers)}
                    </Col>
                    <Col xs={12} md={2}>
                        {/* <div style={{fontSize: "10em", transform: "translateX(-100px)"}}>
                            {NextIcon()}
                        </div> */}
                        <br/><br/><br/>
                        {values.filter((pv, index)=>index<amntCode).map(({mort, repr}, index2)=><div key={mort+"--"+index2} style={{height: canvasSize+23+"px"}}>
                                Mortality: <i>{mort}</i> <br/>
                                Reproduction: <i>{repr}</i>
                            </div>)}
                    </Col>
                    <Col xs={12} md={1}></Col>
                    <Col xs={12} md={3}>
                        <b>modified code</b>
                        <br/><br/>
                        {getPixelVisualizers(pixelVisualizersEdit)}
                    </Col>
                    <Col xs={12} md={3}>
                        <Button text="download JSON" onClick={()=>{downloadTxtFile(true)}}  options={{noBottom: true}}/>
                        <Button text="download XML" onClick={()=>{downloadTxtFile(false)}} />
                    </Col>

                </Row>
            </Container>
        </Wrapper>
    </React.Fragment>
    }

    
}

export default SPG;