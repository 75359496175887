export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_ID_LIST: Symbol("data/idlist"),
    SET_TAGS: Symbol("data/tags"),
    SET_TAXONOMY: Symbol("data/taxonomy"),
    SET_CURRENT_KBI: Symbol('data/kbicurr'),
    SET_LOGGED_IN_MEMBER: Symbol('data/login/member'),
    SET_CURRENT_PROJECT: Symbol('data/currentproject'),
    SET_CURRENT_CHAPTER: Symbol('data/currentchapter'),
    SET_CATEGORIES: Symbol('data/categories')
});

const initialState = {
  client: null,
  idList: [],
  tags: null,
  taxonomy: null,
  currentKBI: null,
  member: null,
  currentProject: null,
  currentChapter: null,
  categories: null,
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_ID_LIST: {
      return {
        ...state,
        idList: action.idList
      };
    }
    case ACTIONS.SET_TAGS: {
      return {
        ...state,
        tags: action.tags
      };
    }
    case ACTIONS.SET_TAXONOMY: {
      return {
        ...state,
        taxonomy: action.taxonomy
      };
    }
    case ACTIONS.SET_CURRENT_KBI: {
      return {
        ...state,
        currentKBI: action.kbi
      };
    }
    case ACTIONS.SET_LOGGED_IN_MEMBER: {
      return {
        ...state,
        member: action.member
      };
    }
    case ACTIONS.SET_CURRENT_PROJECT: {
      return {
        ...state,
        currentProject: action.currentProject
      }
    }
    case ACTIONS.SET_CURRENT_CHAPTER: {
      return {
        ...state,
        currentChapter: action.currentChapter
      }
    }
    case ACTIONS.SET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories
      }
    }
    default: {
      return {
        ...state
      };
    }
  } 
};
