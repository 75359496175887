
import React from 'react';
import {ContainerPadding, Wrapper, Section, Text} from '../../theme'
import SCQuery from '../../components/helper/SCQuery'


const Drafts = ()=>{
    return <SCQuery single={true} query={'*[_id in path("drafts.**")]{_id, title, _type}'}>
        {(drafts)=>{
            return <Section>
                <Text>
                We have found the following items in the CMS which are listed as drafts, please update them.
                <br/><br/>
                {drafts.map((draft, index)=><Wrapper key={index} noBottom={index<(drafts.length-1)}><ContainerPadding size="5px">{draft.title || draft._id} (<i>{draft._type}</i>) </ContainerPadding></Wrapper>)}

                </Text>
            </Section>
        }}
        </SCQuery>
}

export default Drafts;