import React from 'react';
import { Rower, MemberListContainer } from '../../theme';
import MemberListElement from '../../components/member/MemberListElement'
import SCQuery from '../../components/helper/SCQuery'
import ShuffleArray from '../../utilities/ShuffleArray'

const MemberList = ({role, notrole, preventShuffle, showEmail}) => {

  return <SCQuery query={"*[_type == 'member'] | order(_updatedAt desc) {_id, firstname, lastname, 'avatar':avatar.asset->url, shortbio, longbio, email, 'slug':slug.current, role, preventPlatform}"}>
        {(memberdata)=>{
            if (role){
                memberdata = memberdata.filter(member=>member.role===role);
            }

            if (notrole){
                memberdata = memberdata.filter(member=>member.role!==notrole);
            }


            if (!preventShuffle){
                memberdata = ShuffleArray(memberdata);
            }

            return (<MemberListContainer>
            <Rower center="xs"> 
                {memberdata.map(member => (
                    member.preventPlatform?null:<MemberListElement showEmail={showEmail} contact={member.email} bioshort={member.shortbio} firstname={member.firstname} lastname={member.lastname} url={member.avatar} slug={member.slug} key={member.firstname + "-" + member.lastname}></MemberListElement>
                ))}
            </Rower>
            </MemberListContainer>  )
          }}
    </SCQuery>
}

export default MemberList;

