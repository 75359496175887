import React from 'react'
import {Grid, Row, Col, Title, SuperTitle, CircleImage, ContainerSides, SubTitle, Wrapper, InlineText, ContainerPadding, ResponsiveImageWithEffect} from '../theme'
import SCQuery from '../components/helper/SCQuery';
import BlockContent from '@sanity/block-content-to-react';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

const Chapter = (props) => {

    return <Grid >
        <br/>
        <MetaTags>
                <title>{"SEADS / Chapters"}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS / Chapters"} />
                <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
        <Row center="xs">
            <Col xs={12}>
                <SuperTitle>Chapters</SuperTitle>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={12} md={8} lg={7}>
                <SCQuery single={true} query={"*[_type == 'siteSettings'][0]{chapters}"}>
                    {({chapters})=>{
                    return <BlockContent blocks={chapters} style={{lineHeight: "500px"}} />
                    }}
                </SCQuery>
                <br/><br/>
            </Col>
        </Row>
        <Row center="xs">
                <SCQuery query={"*[_type == 'chapter']{'slug':slug.current, 'logo':logo.asset->url}"}>
                    {(chapters)=>{
                    return chapters.map(({slug, logo})=><Col xs={9} sm={2} lg={3} key={slug} style={{maxWidth: "200px", margin: "20px"}}>
                        <Link to={`/chapter/${slug}`}>
                            <ResponsiveImageWithEffect src={logo} />
                        </Link>
                    </Col>)
                    }}
                </SCQuery>
                <Col xs={12}>
                    <br/><br/><br/><br/><br/>
                </Col>
        </Row>
    </Grid>

}
    

export default Chapter;
