import React, {useState} from 'react';
import Expand from 'react-expand-animated';
import {Wrapper, Container, ContainerPadding, Line} from '../../theme'
import Button from '../../components/general/Button';

const CollapsibleContainer = ({children, beginOpen, title, noPadding, noWrapper, noContainer, notBold, hideTitleTop})=>{
    const [open, setOpen] = useState(beginOpen);
    const [transit, setTransit] = useState(false);
    const duration = 400;

    const toggle = ()=>{
        setOpen(!open);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
    }

    const getButton = ()=><Button text={title} onClick={toggle} options={{bold:!notBold, noTop: hideTitleTop}}  />
    const getContent = ()=>noPadding? children : <ContainerPadding>{children}</ContainerPadding>
    const getOuter = ()=>(<div>
        {getButton()}        
            <Expand open={open} duration={duration}>
                {noWrapper?getContent():
                <Wrapper noTop>
                    {getContent()}
                </Wrapper>
                }
            </Expand>
            {transit?<Line />:null}
    </div>)
    return noContainer?getOuter():<Container>{getOuter()}</Container>
}

export default CollapsibleContainer;