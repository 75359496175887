import {FiSend, FiEdit} from 'react-icons/fi'
import {IoMdClose, IoMdMore} from 'react-icons/io'
import {MdNavigateNext, MdNavigateBefore} from 'react-icons/md'
import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import {AiFillLinkedin, AiFillFacebook, AiFillTwitterCircle, AiFillInstagram} from 'react-icons/ai'

export const SendIcon = FiSend;
export const CloseIcon = IoMdClose;
export const NextIcon = MdNavigateNext;
export const PrevIcon = MdNavigateBefore;
export const EditIcon = FiEdit;
export const MoreIcon = IoMdMore;
export const LeftIcon = FaArrowLeft;
export const RightIcon = FaArrowRight;
export const FacebookIcon = AiFillFacebook;
export const TwitterIcon = AiFillTwitterCircle;
export const LinkedInIcon = AiFillLinkedin;
export const InstagramIcon = AiFillInstagram;
