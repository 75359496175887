import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import BlockContent from '@sanity/block-content-to-react';
import MetaTags from 'react-meta-tags';
import BlocktoPlainText from '../utilities/BlocktoPlainText' 
import TextPanel from '../components/general/TextPanel';
import { Coller, MainContainer, ResponsiveImage, Rower, InlineText, Title, Line, OnlySmall, ContainerSides, SuperTitle } from '../theme';
import ImageList from '../components/kb/ImageList';
import KbiList from '../components/kb/KbiList';
import SCQuery from '../components/helper/SCQuery';
import MemberListElement from '../components/member/MemberListElement'
import { Link } from 'react-router-dom';

const Project = (props) => {
    let savedProject = useSelector(state=>state.data.currentProject);
    const dispatch = useDispatch();
    const [data, setData] = useState(null);

    useEffect(() => {
        if (data && (!savedProject || (savedProject.title !== data.title))){
            dispatch({ type: DATA_ACTIONS.SET_CURRENT_PROJECT, currentProject: data});
        }
    });


return <SCQuery single={true} query={"*[_type == 'project' && slug.current=='"+props.match.params.slug+"'][0]{_type, type, description, participants, title, _id, 'slug':slug.current, included_images[]->{title, localeDescription, 'url':image.asset->url}, resources[]->{title, _id, _type, 'url':image.asset->url, 'link':url}, 'cover':cover->image.asset->url, 'hyperproject':*[_type == 'hyperproject' && references(^._id)][0]{title, description, 'slug':slug.current, 'cover':cover->image.asset->url}, members[]->, members[]->{_id, firstname, lastname, 'avatar':avatar.asset->url, shortbio, longbio, email, 'slug':slug.current}, partners[]{link, 'logo':logo.asset->url}}"}>
{(project)=>{
    let resources = [], images = [];
    
    if (!data || (data.title !== project.title)){
        setData(project);
    }

    if (project.resources){
        resources = project.resources.filter(resource=>resource._type!=="image_kbi")
        images = project.resources.filter(resource=>resource._type==="image_kbi")
    }
    
    return (
        <MainContainer>
             <MetaTags>
                <title>{"SEADS / " + project.title}</title>
                <meta name="description" content={BlocktoPlainText(project.description)} />
                <meta property="og:title" content={"SEADS / " + project.title} />
                <meta property="og:image" content={project.cover+"?w=720"} />
            </MetaTags>
            <SuperTitle><span dangerouslySetInnerHTML={{ __html: project.title}} /></SuperTitle>
            <Rower center='xs'>
                <Coller xs={12} sm={8}>
                    <ResponsiveImage src={`${project.cover}?w=${Math.floor(window.innerWidth*.8)}`} alt={project.title} />
                </Coller>
            </Rower>
            <br/><br/>
            <Rower center="xs">
                <Fragment>
                    {resources.length ?
                        <Coller sm={10} xs={12} md={4} style={{paddingTop: "10px"}}>
                            <KbiList data={resources} title="resources" />
                            <OnlySmall>
                                <br/><br/>
                            </OnlySmall>
                        </Coller>
                    : null }
                        <Coller xs={12} md={8}>
                            <ContainerSides size="10px">
                                <InlineText>
                                    <BlockContent blocks={project.description} style={{lineHeight: "500px"}} />
                                </InlineText>

                                {project.included_images && project.included_images.length>0?
                                project.included_images.map(image=><div>
                                    <ResponsiveImage src={`${image.url}`} alt={image.title} />
                                    <InlineText>
                                        {(image && image.localeDescription && image.localeDescription.en)?
                                            <BlockContent blocks={image.localeDescription.en} style={{lineHeight: "500px"}} />
                                            : null}
                                </InlineText>
                                <br/><br/>
                                </div>):null}
                                {project.participants?<div>
                                     
                                    <InlineText>
                                        <i>
                                        <BlockContent blocks={project.participants} style={{lineHeight: "500px"}} />
                                        </i>
                                    </InlineText>
                                    <br/><br/>
                                </div>:null}
                            </ContainerSides>
                        </Coller>
                    </Fragment>
            </Rower>
            <Rower>
                <Coller xs={12}>
                    <ImageList imagegroup={images} />
                </Coller>
            </Rower>
            
                <Rower center="xs">
                        <Coller xs={12}>
                            <br/><br/>
                        </Coller>
                        {project.hyperproject ?
                            <Coller md={3} sm={6} xs={12}>
                                <TextPanel img={`${project.hyperproject.cover}`} link={`/hyperproject/${project.hyperproject.slug}`} title={project.hyperproject.title} text={project.hyperproject.description} />
                            </Coller>
                        : null }
                        <Coller xs={12} sm={false}>
                            <br/><br/>
                        </Coller>
                        {project.members ? <Coller xs={8} sm={6} md={6}>
                            <Rower center="xs">
                                
                                <div style={{width: "100%", textAlign: "center"}}>
                                <Title style={{paddingBottom: "10px"}}>Participating SEADS members</Title>
                                </div>    
                                {project.members.map((member, index)=><MemberListElement contact={member.email} bioshort={member.shortbio} firstname={member.firstname} lastname={member.lastname} url={member.avatar} slug={member.slug} key={member.firstname + "-" + member.lastname}></MemberListElement>)}

                                {(project.partners && project.partners.length)? 
                                
                                <React.Fragment>
                                    <div style={{width: "100%", textAlign: "center"}}>
                                    <br/><br/>
                                        <Title style={{paddingBottom: "10px"}}>Partners</Title>
                                    </div>
                                    {project.partners.map(({link, logo})=><Coller key={link}> 
                                        <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                                            <ResponsiveImage src={logo} style={{maxWidth: "220px"}} />
                                        </a>
                                    </Coller>)}
                                </React.Fragment>
                                
                                : null}
                                
                            </Rower>
                        </Coller> 
                        : null}
                        </Rower>
                    
            <br /><br />
        </MainContainer>
    )}}
</SCQuery>
}
    


export default Project;
