import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-component';
import { Redirect } from 'react-router';
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../../reducers/DataReducer"
import { galleryImageWidth, galleryBorder, ResponsiveImageWithEffect, MasonryWrapper } from "../../theme";


const masonryOptions = {
    transitionDuration: 200,
    columnWidth: galleryImageWidth + galleryBorder * 2,
    gutter: galleryBorder,
    fitWidth: true
};

//todo: set amount
const Gallery = (props)=>{
        let renderedImages = [];
        const [redirect, setRedirect] = useState(null);
        const randomAmount = props.randomAmount || false;
        masonryOptions.columnWidth = props.columnWidth || masonryOptions.columnWidth;
        const idlist = [];
        const [selectedID, setSelectedID] = useState(null);
        const dispatch = useDispatch();
        const storedata = useSelector(state => state.data);

        useEffect(() => {
            if (storedata.idList.length>0 && selectedID){
                setRedirect(selectedID);
                setSelectedID(null);
            }
        }, [storedata.idList.length, selectedID]);

        
        props.images.forEach(image => {
            idlist.push(image.id||image._id);
        });

        let previousIndex = null;
        for (let i = 0; i <  (randomAmount?randomAmount:props.images.length); i++) {
            let getIndex = ()=>{
                return (randomAmount? i%props.images.length : i);
            }
            let idx = getIndex();
            while(idx == previousIndex){
                idx = getIndex();
            }

            previousIndex = idx;

            renderedImages.push(
                <MasonryWrapper key={i} width={masonryOptions.columnWidth} onClick={()=>{handleClick(props.images[idx])}}>
                    <ResponsiveImageWithEffect src={props.images[idx].url+'?w=400'}  />
                </MasonryWrapper>
            )
        }



        const handleClick = (data) => {
            setSelectedID(data.id||data._id)
            dispatch({ type: DATA_ACTIONS.SET_ID_LIST, idList: idlist});
        };

        if (redirect) {
            return <Redirect push to={{pathname: "/image/"+redirect}} />;
        } else {
            return <Masonry
            elementType={'ul'} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        >
            {renderedImages}
        </Masonry>
        }
    }


export default Gallery;

