import React from 'react';
import { BeatLoader } from 'react-spinners';
import { CenterContainer, ErrorMessage, Overlay, SubTitle} from '../../theme';

export const SimpleLoader = ()=>{
    return <BeatLoader 
    sizeUnit={"px"}
    size={20}
    margin={'4px'}
    color={'#000'}
    loading={true}
/>
}

const Loader = ({ text }) => (
    <Overlay>
        <CenterContainer>
            <SimpleLoader />
            <SubTitle>Loading</SubTitle>
            { text ? <ErrorMessage>{text}</ErrorMessage> : null }
        </CenterContainer>
    </Overlay>
)

export default Loader;