import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import GoogleLogin, {GoogleLogout} from 'react-google-login';
import {MenuItem} from '../../components/other/Nav'
import {SimpleLoader} from '../../components/helper/Loader'
import {settings} from '../../theme/config.json'

  const Memberlogin = ()=>{

    const storedata = useSelector(state => state.data);
    const [googleEmail, setGoogleEmail] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (googleEmail && storedata.client && !storedata.member && !loading){
            setLoading(true);
            storedata.client.login(googleEmail)
            .then(()=>{setLoading(false)})
        }
    });

    const responseGoogle = (response) => {
        if (window.location.hostname === 'localhost'){
            let backup = {profileObj: {
                            imageUrl: "https://lh3.googleusercontent.com/a-/AOh14Gi6dXeXAjf-0PdexeXni9Q8xHvShg_fltMi0pt-=s96-c",
                            email: "pieter@seads.network",
                            name: "Pieter Steyaert",
                            givenName: "Pieter",
                            familyName: "Steyaert"}}
            response = backup;
        }
    
        if (response && response.profileObj){
            setGoogleEmail(response.profileObj.email);
        }
      }
    
    const logOut = ()=>{
        setLoading(true);
        storedata.client.logout()
        .then(()=>{
            setGoogleEmail(null);
            setLoading(false);
        })
    }

    if (loading){
        return <MenuItem> <SimpleLoader /> </MenuItem>
    } else if (storedata.client && storedata.member){
        return <GoogleLogout
        clientId={settings.googleLoginToken}
        buttonText="Logout"
        onLogoutSuccess={logOut}
        cookiePolicy={'single_host_origin'}
        render={renderProps => (
            <MenuItem onClick={renderProps.onClick}> Logout </MenuItem>
        )}
    >
    </GoogleLogout>
    } else {
    return <GoogleLogin
    clientId={settings.googleLoginToken}
            render={renderProps => (
                <MenuItem onClick={renderProps.onClick}> Login </MenuItem>
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
            isSignedIn={true}
        />
    }
  }


  export default Memberlogin;

