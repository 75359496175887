import React, { useEffect, useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { SubTitle, Title } from '../../theme';
import {SimpleLoader} from '../helper/Loader';
import SanityBlockContent from '@sanity/block-content-to-react';

export const ListZoteroList = (props)=>{
    console.log(props)
    return props.list.map(({title,_key, url, description})=><ZoteroList title={title} url={url} description={description} key={_key}/>)
}

const ZoteroList = ({title, url, description}) => {
    const [data, setData] = useState(null);
    useEffect(()=>{
        if(!data && url && title){
            fetch(`${url}&format=json&sort=date`).then(function(response) {
        return response.json();
            }).then(function(nd) {
                setData(nd);
            }).catch(function(err) {
                console.log('Fetch Error :-S', err);
            });
        }
    
    },[data])
    return <div>
        <Title>{title}</Title>
        <SubTitle>
            <SanityBlockContent blocks={description} />
        </SubTitle>
        <Publications data={data} />
    </div>
}

export const MemberPublicationList = ({name})=>{
    const [data, setData] = useState(null);
    useEffect(()=>{
        if(!data && name){
            fetch(`https://api.zotero.org/groups/4992484/items/top?q=${name}&direction=desc&format=json&sort=date`).then(function(response) {
        return response.json();
            }).then(function(nd) {
                setData(nd);
            }).catch(function(err) {
                console.log('Fetch Error :-S', err);
            });
        }
    
    },[data])
    return data && data.length?<div>
        <Title>Publications</Title>
        <Publications data={data} />
    </div>:null
}

export const Publications = ({data})=>{
    const content = data?<div>
        {
            data.map(({key, data})=>{
            
                const content = <span><b>{data.title};</b>
                {data.creators.map(({firstName, lastName})=><span key={lastName}> {lastName}, {firstName}; </span>)}
                <i>{data.conferenceName}</i>
                <i>{data.meetingName}</i>
                <i>{data.publicationTitle}</i>
                <i>{data.bookTitle}</i>, {data.date}
                <br/>
                <br/>
                </span>

                if (data.url){
                    return <a key={key} href={data.url} target="_blank" style={{textDecoration: "underline"}}>{content}</a>
                } else {
                    return content
                }
            })
        }
        </div>:<SimpleLoader/>

return <div> {content}

</div>
}


export default ZoteroList

