import React from 'react';
import { Coller, MainContainer, Rower, SubTitle, Title } from '../theme';
import SCQuery from '../components/helper/SCQuery'
import ProjectTile from '../components/project/ProjectTile';
import MetaTags from 'react-meta-tags';

const Overview = (props) => 
        <div>
            <MetaTags>
                <title>{"SEADS / Projects"}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS / Projects"} />
                <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
        <SCQuery single query={'*[_type == "project"]{type, _id, hide, "slug" : slug.current, "category":categories->title, _createdAt, begin, title, type, "hyperprojects":hyperprojects[]->{title}}'}>
        
            {(data)=>{

                let dictionary = new Object();

                data.forEach(element => {
                    if (element.type in dictionary){
                        dictionary[element.type].push(element)
                    } else {
                        dictionary[element.type] = [element]
                    }
                });

                if ("art" in dictionary){
                    let artprojects = dictionary.art;

                    let sorted = new Object();
                    sorted.undefined = [];
                    
                    artprojects.forEach(project=>{
                        if (project.hyperprojects && project.hyperprojects.length && project.hyperprojects[0]){
                            let title = project.hyperprojects[0].title
                            if (title in sorted){
                                sorted[title].push(project)
                            } else {
                                sorted[title] = [project]
                            }
                        } else {
                            sorted.undefined.push(project)
                        }
                    })
                    dictionary.art = sorted;
                }
                
                // sort by date
                for (const key in dictionary){

                    const sorter = (a, b)=>{
                        const getdate = (project)=>project.begin?new Date(project.begin) : new Date(project._createdAt)
                        return getdate(a) - getdate(b);
                    }

                    if (key == "art"){
                        for (const hyperproject in dictionary[key]){
                            const projects = dictionary[key][hyperproject];    
                            dictionary[key][hyperproject].sort(sorter)
                        }
                    } else {
                        dictionary[key].sort(sorter);
                    }
                }

                const projects = [];    
                for (const key in dictionary){
                    const section = dictionary[key]
                    projects.push(<div key={key}>
                        <Rower>
                        <Title>{key}</Title>
                        
                        {   
                            (()=>{
                                const renderproject = (project)=>{
                                    if (!project.hide){
                                        return <li key={project._id}>
                                        <a href={`/project/${project.slug}`}>
                                        <span dangerouslySetInnerHTML={{ __html: project.title}} />
                                        </a>
                                    </li>
                                    } else {
                                        return undefined
                                    }
                                }
                                if (key == "art"){
                                    const hyperprojects = [];

                                    const addArtProjects = (hyper)=>{
                                        hyperprojects.push(<div key={hyper}>
                                            <SubTitle style={{textAlign: "left"}}>
                                                {(hyper == "undefined")?"Other":hyper}
                                            </SubTitle>
                                            <ul>
                                            {section[hyper].map(renderproject)}
                                            </ul>
                                        </div>)
                                    }

                                    for (const hyper in section){
                                        if (hyper != "undefined"){
                                            addArtProjects(hyper)
                                        }
                                    }

                                    addArtProjects("undefined");

                                    return <div>
                                        <br/><br/>
                                        {hyperprojects}
                                        </div>
                                } else {
                                    return <ul>{section.map(renderproject)}</ul>
                                }
                            })()
                        }
                        
                        </Rower>
                        <br/>
                    </div>)
                }




                
            
                return (
                    <MainContainer>
                        {projects}
                </MainContainer>
                )
            }}
        </SCQuery>
    </div>
   
export default Overview;
