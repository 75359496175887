import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom'
import { ACTIONS as DATA_ACTIONS } from "../../reducers/DataReducer"
import BlockContent from '@sanity/block-content-to-react';
import { ResponsiveImage, Title, Row, Grid, Col, Line, Text, SubTitle, Section} from "../../theme";
import Button from "../../components/general/Button";
import ProjectTile from '../../components/project/ProjectTile';
import SCQuery from '../../components/helper/SCQuery'
import Dater from '../../components/helper/Dater'
import {IoMdClose} from 'react-icons/io' //change icon
import {CopyToClipboard} from 'react-copy-to-clipboard';
import toast from '../../utilities/toast'

export const ExtendedUrlComponent = ({title, description, appearsIn, thumbnail, projectlink, owner, createDate, updateDate, id, priv})=>{
    const dispatch = useDispatch();;

    appearsIn = appearsIn || [];

    let relatedProjects = (()=>{
        if (appearsIn.length == 0){
            return null;
        }
        let projects = appearsIn.map((project)=><ProjectTile key={project.title} img={project.cover} link={`/${project._type}/${project.slug}`} title={project.title} container/>)
        return <div>
            {projects.length?<Title>Appears in</Title>:null}
            {projects}
        </div>
    })();

    const subtitle = ()=>{
        if (owner && owner.name){
            let text = <SubTitle>by {owner.name}</SubTitle>;
            if (owner.url){
                return <a href={owner.url} target="_blank">{text}</a>
            } else {
                return text;
            }
        }
    }

    return (<Section style={{width: "100% !important"}}>
            <Row>
                <Col sm={2}>
                <Section style={{position: "absolute"}}>
                <IoMdClose  onClick={()=>{dispatch({type: DATA_ACTIONS.SET_CURRENT_KBI, kbi:null})}} style={{fontSize: '40px'}} />
                </Section>
                </Col>
            </Row>
            
            <Row center="xs">
                <Col xs={4}>
                    <ResponsiveImage src={`${thumbnail}?w=400`} alt={title} />
                </Col>
                <Col xs={12}>
                    <Title>{title}</Title>
                    {subtitle()}
                </Col>
                <Col xs={12}>
                    {description ? <Text><BlockContent blocks={description} style={{lineHeight: "500px"}} /></Text>:null}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    Created on <i><Dater date={createDate} /></i>
                </Col>
                <Col xs={12}>
                    Last updated on <i><Dater date={updateDate} /></i>
                </Col>
                {priv? <Col xs={12}>
                    Hidden from public
                </Col>:null}
            </Row>
            <br/>
            <Row>
                <Col xs={12}>
                    <Button text="go to project page" link={projectlink} options={{external: true, noBottom:true}} />
                </Col>
                <Col xs={12}>
                    <CopyToClipboard text={`https://seads.network${window.location.pathname}/${id}`}
                        onCopy={()=>{toast('The link has been copied to your dashboard')}}>
                        <Button text="Share Project" onClick={()=>{
                        }} options={{external: true}} />
                    </CopyToClipboard>
                    
                </Col>
            </Row>
            {relatedProjects?<Section>
                <br /><br />
                <Line />
                <br /><br />
                <Row center="xs">
                    <Col xs={12} sm={10} md={6} lg={4} xl={3}>
                        {relatedProjects}
                    </Col>
                </Row>
                <br />
            </Section>:null}
            </Section>
    )
}

const ExtendedUrlView = ({id}) => {
    return <SCQuery single query={'*[_id == "'+id+'"][0]{title, private, _id, localeDescription, owner, _createdAt, _updatedAt, projectlink, "thumbnail":thumbnail.asset->url, "appearsIn":*[(_type == "project" || _type == "hyperproject") && ^._id in resources[]._ref]{title, description, "cover":cover->image.asset->url}}'}>
        {(EUD)=>{
            return (
                <ExtendedUrlComponent 
                    title={EUD.title} 
                    description={(EUD.localeDescription && EUD.localeDescription.en)?EUD.localeDescription.en:EUD.description} 
                    thumbnail={EUD.thumbnail}
                    createDate = {EUD._createdAt}
                    updateDate = {EUD._updatedAt}
                    owner = {EUD.owner}
                    id = {EUD._id}
                    projectlink = {EUD.projectlink} 
                    private={EUD.private}
                    />
                    
            )}}
    </SCQuery>
}


export default ExtendedUrlView;

