import React, {useState} from 'react';
import { useSelector} from "react-redux";
import BlockContent from '@sanity/block-content-to-react'
import { Redirect } from 'react-router-dom'

import MemberList from '../components/member/MemberList'
import {Grid, Row, Col, Title, CircleImage, ContainerSides} from '../theme'
import MemberProjects from '../components/member/MemberProjects'
import SCQuery from '../components/helper/SCQuery'
import Button from '../components/general/Button'
import MetaTags from 'react-meta-tags';
import Segment from '../components/general/Segment'
import { MemberPublicationList } from '../components/kb/ZoteroList';

const OptionalSocial = ({name, social})=>(
    social? <Button text={name} link={social} options={{external:true, noTop: true}}/> : null 
)

const Member = ({ match })=>{
    const client = useSelector(state=>state.data.client);
    const [forceAll, setForceAll] = useState(false);

    if (match.params && match.params.slug && client && !forceAll){
        
        return <SCQuery single={true} query={'*[_type=="member" && slug.current=="'+match.params.slug+'"][0]'+client.memberSelectors}>
        {(selectedmember)=>{
            if (!selectedmember._id){
                setForceAll(true);
            }
            
            return <Grid > 
                <MetaTags>
                <title>{"SEADS / "+selectedmember.firstname+" "+selectedmember.lastname}</title>
                <meta name="description" content={selectedmember.shortbio} />
                <meta property="og:title" content={"SEADS / "+selectedmember.firstname+" "+selectedmember.lastname} />
                <meta property="og:image" content={selectedmember.avatar+"?w=720"} />
            </MetaTags>
                <br/><br/>
                <Row center="xs">
                    <Col xs={12}>
                        <Title>
                            {selectedmember.firstname} {selectedmember.lastname}
                        </Title>
                    </Col>
                </Row>
                <Row>
                <Col xs={8} xsOffset={2} smOffset={0} sm={3} md={2}>
                    <CircleImage src={client.squareImage(selectedmember.avatar, 400)} />
                </Col>
                <Col xs={12} sm={9} md={8}>
                    <ContainerSides>
                        <BlockContent blocks={selectedmember.longbio} />
                        <br/><br/>
                        <Button text={selectedmember.email} link={'mailto:'+selectedmember.email} options={{external:true}} />
                        <OptionalSocial name="website" social={selectedmember.website} />
                        <OptionalSocial name="instagram" social={selectedmember.instagram} />
                        <OptionalSocial name="facebook" social={selectedmember.facebook} />
                        <OptionalSocial name="twitter" social={selectedmember.twitter} />
                    </ContainerSides>
                    <br/><br/><br/><br/>
                </Col>
                </Row>
                    <Title>Projects</Title>
                    <MemberProjects member={selectedmember} />

                    <MemberPublicationList name={selectedmember.lastname} />
                </Grid>
        }
        }</SCQuery>

    } else {
        return null;
    }
}

export default Member;