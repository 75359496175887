import React, {useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import BlockContent from '@sanity/block-content-to-react';
import { useHistory } from 'react-router-dom'
import { ResponsiveImage, Title, Rower, Coller, Line, Text} from "../theme";
import Button from "../components/general/Button";
import ProjectTile from '../components/project/ProjectTile';
import SCQuery from '../components/helper/SCQuery'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"


const GoToRandomImage = ()=>{
    const history = useHistory();
    const dispatch = useDispatch();

    return <SCQuery query={"*[_type == 'image_kbi']{_id}"}>
        {(images)=>{
            return <Button text="random image" onClick={()=>{
                    let newId = images[Math.floor(Math.random()*images.length-1)]._id;
                    dispatch({ type: DATA_ACTIONS.SET_ID_LIST, idList: []});
                    history.push("/image/"+newId);
                }}/>
            }}
    </SCQuery>
}


const ImageViewComponent = ({title, url, description, appearsIn, id})=>{

    const storedata = useSelector(state => state.data);
    const history = useHistory();

    appearsIn = appearsIn || [];

    let relatedProjects = (()=>{
        let projects = appearsIn.map((project)=><ProjectTile key={project.title} img={project.cover} link={`/${project._type}/${project.slug}`} title={project.title} container/>)
        return <div>
            {projects.length?<Title>Appears in</Title>:<Button link='/' text='go home'></Button>}
            {projects}
        </div>
    })();

    let navigation = (()=>{
        
        const changeIndex = (adder)=>{
            let idx = storedata.idList.indexOf(id);
            idx+=adder;
            if (idx < 0){
                idx = storedata.idList.length-1;
            } else if (idx >= storedata.idList.length){
                idx = 0;
            }
            history.push("/image/"+storedata.idList[idx]);
        }

        let leftNav, rightNav;

        if (storedata.idList && storedata.idList.length > 0){
            leftNav = <Coller xs={12} sm={12} md={6} lg={4}><Button text="previous image" onClick={()=>{changeIndex(-1)}} /></Coller>;
            rightNav = <Coller xs={12} sm={12} md={6} lg={4}><Button text="next image" onClick={()=>{changeIndex(+1)}} /></Coller>;
        }

        return <Rower>
                    {leftNav}{rightNav}
                    <Coller xs={12} sm={12} md={6} lg={4}>
                        <GoToRandomImage/>
                    </Coller>    
                </Rower>
        })()

    return (
        <div>
                <Rower>
                    <Coller xs={12} sm={10} md={8} lg={6} smOffset={1} mdOffset={2} lgOffset={3}>
                        <Rower>
                            <Coller xs={12} sm={12} md={6} lg={4}>
                                <Title>{title}</Title>
                            </Coller>
                        </Rower>
                        {navigation}
                        <br/>
                        <ResponsiveImage src={url} height={"60vh"}/>
                        {description ? <div><br/><Text><BlockContent blocks={description} style={{lineHeight: "500px"}} /></Text></div>:null}
                    </Coller>
                </Rower>
                <br /><br />
                <Line />
                <br /><br />
                <Rower center="xs">
                    <Coller xs={12} sm={10} md={6} lg={4} xl={3}>
                        {relatedProjects}
                    </Coller>
                </Rower>
                <br />
            </div>
    )
}

const ImageView = (props) => {
    let initialID = null;
    if(props && props.match && props.match.params && props.match.params.id){
        initialID = props.match.params.id;
    } else if (props.id){
        initialID = props.id;
    }
    const [id, setId] = useState(initialID);
    const { listen } = useHistory()

    useEffect(() => {
        const unlisten = listen((location) => {
            const path = location.pathname;
            if (path.includes('/image/')){
                setId(path.replace('/image/', ''));
            }
        })
          return unlisten
    });

    return <SCQuery single query={'*[_id == "'+id+'"][0]{title, _id, localeDescription, "url":image.asset->url, "appearsIn":*[(_type == "project" || _type == "hyperproject") && ^._id in resources[]._ref]{title, _id, "slug":slug.current, description, "cover":cover->image.asset->url, _type}}'}>
        {(imagedata)=>{
            
            var description = imagedata.description?imagedata.description:(imagedata.localeDescription && imagedata.localeDescription.en)?imagedata.localeDescription.en:[];
            
            return (
                <div>
                    <br/><br/><br/>
                    <ImageViewComponent title={imagedata.title} id={imagedata._id} url={imagedata.url} description={description} appearsIn={imagedata.appearsIn} />
                </div>
            )}}
    </SCQuery>
}


export default ImageView;

