import React, {useState, useEffect} from 'react'
import { useSelector } from "react-redux";
import {Grid, Row, Col, Title, SuperTitle, CircleImage, ContainerSides, SubTitle, Wrapper, InlineText, ContainerPadding} from '../theme'
import SCQuery from '../components/helper/SCQuery';
import BlockContent from '@sanity/block-content-to-react';
import Button, {Select} from '../components/general/Button'
import ProjectTile from '../components/project/ProjectTile';
import MetaTags from 'react-meta-tags';
import Dropdown from 'react-dropdown';
import "../index.css"

const Education = ()=>{
    const [category, setCategory] = useState(0);
    const categories = useSelector(state=>{
        if (state && state.data && state.data.categories){
            return [{title: "Featured"}].concat(state.data.categories.filter(f=>f.type === "education"))
        } else {
            return null;
        }
    });

    

    const client = useSelector(state=>state.data.client);
    const [projects, setProjects] = useState(null);

    useEffect(() => {
        if (client && !projects){
            client.getEducationalProjects()
            .then(setProjects);
        }
    }, [client, projects]);

    const getCategories = ()=>{
        if (categories){
            return categories.map((c, i)=><option key={i} value={i}>{c.title}</option>)
        } else {
            return null;
        }
    }

    if (categories){

    return <Grid>
        <br/>
        <MetaTags>
                <title>{"SEADS / Education & Advocacy"}</title>
                <meta name="description" content={"SEADS (Space Ecologies Art and Design) is a transdisciplinary and cross-cultural collective of artists, scientists, engineers and activists"} />
                <meta property="og:title" content={"SEADS / Education"} />
                <meta property="og:image" content={"https://cdn.sanity.io/images/t39t9igo/production/6ca40a8b6ed15ae6d03c50e697764fa3ba748f05-1322x547.png?w=720"} />
            </MetaTags>
        <Row center="xs">
            <Col xs={12}>
                <SuperTitle>Education & Advocacy</SuperTitle>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={12} md={8} lg={7}>
                <SCQuery single={true} query={"*[_type == 'siteSettings'][0]{education}"}>
                    {({education})=>{
                        return <BlockContent blocks={education} style={{lineHeight: "500px"}} />
                    }}
                </SCQuery>
            </Col>
        </Row>
        <Row center="xs">
            <Col xs={12} md={3} lg={2}>
                <br/><br/>
                <Button text="contact us" link={"mailto:contact@seads.network"} options={{external:true}} />
                <br/><br/><br/>
            </Col>
        </Row>
        <Row center="xs">
            <Col sm={12}   style={{textAlign: "center"}}>
                <div>
                <InlineText>
                    <b  style={{width: "500px"}}>
                        Select category
                    </b> <br/>
                </InlineText>
                </div>
                <div style={{width: "200px", margin: "auto", marginTop: "10px"}}>
                    <Dropdown value={categories[0]} options={categories.map((c, index)=>({label: c.title, value: {...c, index}}))} onChange={c=>{setCategory(c.value.index)}} value={categories[category].title} placeholder="Select an option" />
                </div>
            </Col>
        </Row>
        <Row center="xs">
                <Col xs={12} md={8} lg={7}>
                    {category!==0?<BlockContent blocks={categories[category].description} style={{lineHeight: "500px"}} />: null}
                </Col>
                <Col xs={12}>
                    <Row center="xs">
                        {projects?projects.filter(p=>{
                            if (category === 0){
                                return p.featured;
                            } else {
                                return p.category === categories[category].title
                            }
                        }).map(project=> <Col marginBottom key={`portfolio-${project.slug}`} lg={3} md={4} sm={4} xs={12}>
                                            <ProjectTile key={project.slug} img={`${project.cover}?w=400`} link={`/${project._type}/${project.slug}`} title={project.title} container/>
                                        </Col>):null}
                    </Row>
                </Col>
            <Col xs={12}>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Col>
        </Row>
    </Grid>
    } else {
        return null;
    }
}

export default Education;